import { Checkbox, TextField, Button, CircularProgress, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../utility/dimensions';
import '../../../commonStyle.css';
import Dummy from '../../../assets/logo5.svg';
import PatientDetailsCard from '../../../components/PatientDetailsCard';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import themeMain from '../../../theme';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import './style.css';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../utility/axios';
import DocInfoCard from '../../../components/DocInfoCard';
import CancelIcon from '@mui/icons-material/Cancel';

//Obervation Modal
const styleT = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    color: '#fff',
    bgcolor: '#012764',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

//Treatment Modal
const styleO = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    color: '#fff',
    bgcolor: '#012764',
    border: '2px solid #fff',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 4,
};

//Treatment table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: '600'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: '600'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));




const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase()
    return array.filter(value => {
        return value.nurse_name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.treatment.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.treatment_time.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.details.toLowerCase().match(new RegExp(searchTerm, 'g'))

    })
}

const NursePatientDetails = (props) => {

    //Treatment form
    //Form data
    const { id } = useParams();


    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }


    const rows1 = [

        { treatment: "abcdef", time: "10am-12am", addInfo: "Give it after breakfast", nurseStatus: true },
        { treatment: "Ringer's lactate solution", time: "10am-11am", addInfo: "Give it after breakfast", nurseStatus: true }


    ];

    const [rows, setRows] = useState(rows1);



    //Observation details
    const [patientData, setpatientData] = useState({


        pulse: '',
        bp: "",
        temp: '',
        oxymeter: '',
        comments: ''


    })

    const [bpError, setbpError] = React.useState(false);
    const [bpErrorText, setbpErrorText] = React.useState("");

    const [commentsError, setcommentsError] = React.useState(false);
    const [commentsErrorText, setcommentsErrorText] = React.useState("");

    const [tempError, settempError] = React.useState(false);
    const [tempErrorText, settempErrorText] = React.useState("");

    const [pulseError, setpulseError] = React.useState(false);
    const [pulseErrorText, setpulseErrorText] = React.useState("");

    const [oxymeterError, setoxymeterError] = React.useState(false);
    const [oxymeterErrorText, setoxymeterErrorText] = React.useState("");

    //Validate Observation data

    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const validateData = () => {


        if (patientData.bp.length > 7) {
            setbpError(true);
            setbpErrorText('Please enter correct bp');
        }

        setbpError(ValidateSingleField(patientData.bp));
        ValidateSingleField(patientData.bp) ? setbpErrorText('bp cannot be blank') : setbpErrorText('');

        settempError(ValidateSingleField(patientData.temp));
        ValidateSingleField(patientData.temp) ? settempErrorText('temp cannot be blank') : settempErrorText('');

        setpulseError(ValidateSingleField(patientData.pulse));
        ValidateSingleField(patientData.pulse) ? setpulseErrorText('   Pulse cannot be blank') : setpulseErrorText('');

        setoxymeterError(ValidateSingleField(patientData.oxymeter));
        ValidateSingleField(patientData.oxymeter) ? setoxymeterErrorText('Oxygen level cannot be blank') : setoxymeterErrorText('');






        if (!ValidateSingleField(patientData.bp) &&
            !ValidateSingleField(patientData.oxymeter) &&

            !ValidateSingleField(patientData.temp) &&
            !ValidateSingleField(patientData.gender)) {


            return true;


        }
        return false;


    }




    //Treatment Modal
    const [openO, setOpenO] = React.useState(false);
    const handleOpenO = () => setOpenO(true);
    const handleCloseO = () => {
        setOpenO(false);
        setUpdateTreatment(-1);
    }
    // Observation Modal
    const [openT, setOpenT] = React.useState(false);
    const handleOpenT = () => setOpenT(true);
    const handleCloseT = () => setOpenT(false);

    const { height, width } = useWindowDimensions();
    const [date, setDate] = React.useState(new Date());
    const patient = {
        name: 'Priyansh Sunilkumar Solanki',
        age: '22',
        bloodgroup: 'B+',
        contact: '976543210',
        gender: 'Male',
        ename: 'Jayanth Pabothu',
        econtact: '987654321',
        roomno: '341',
        bedno: '21'
    }
    const [patientDetails, setPatientDetails] = useState();
    const [doctorDetails, setDoctorDetails] = useState();
    const [takeObservation, setTakeObservation] = useState(
        [
            (new Date().getHours() <= 11 || (new Date().getHours() == 12 && new Date().getMinutes() <= 59)),
            ((new Date().getHours() >= 13 && new Date().getHours() <= 17) || (new Date().getHours() == 18 && new Date().getMinutes() <= 59)),
            ((new Date().getHours() >= 19 && new Date().getHours() <= 22) || (new Date().getHours() == 23 && new Date().getMinutes() <= 59))
        ]);

    console.log(takeObservation);
    const [loader, setLoader] = useState(true);
    const [loader1, setLoader1] = useState(true);
    const [loader2, setLoader2] = useState(true);
    // React.useEffect(() => {
    //     axiosInstance.get(`/patient/patient_details/?patient_id=${id}`).then(res => {
    //         setLoader(false);
    //         console.log(res.data);
    //         // setPatientData(res.data);
    //     }).catch(err => {
    //         setLoader(false);
    //         console.log(err.response)
    //     });
    // }, []);


    React.useEffect(() => {

        axiosInstance.get(`/patient/patient_details/${id}/`).then(res => {
            setLoader1(false);
            setPatientDetails(res.data);
            axiosInstance.get(`/doctor/doctor_info/${res.data.consulting_doctor}/`).then(res => {
                setLoader2(false);
                console.log(res.data);

                setDoctorDetails(res.data);
                // setPatientData(res.data);
            }).catch(err => {
                setLoader2(false);
                console.log(err.response)
            });
            // setPatientData(res.data);
        }).catch(err => {
            setLoader1(false);
            console.log(err.response)
        });
    }, []);

    const [treatmentList, setTreatmentList] = useState();
    React.useEffect(() => {

        axiosInstance.get(`/nurse/treatments/?patient_id=${id}`).then(res => {

            res.data.map(o => {
                if (o.completed_at == null)
                    o.completed_at = '';
                if (o.created_at == null)
                    o.created_at = '';
                if (o.details == null)
                    o.details = '';
                if (o.nurse_name == null)
                    o.nurse_name = '';
                if (o.treatment == null)
                    o.treatment = '';
                if (o.treatment_time == null)
                    o.treatment_time = '';
            });
            let tmp = res.data.reverse();

            setTreatmentList(tmp);
            setStockData1(tmp);
            console.log(res.data);
        }).catch(err => {
        });
    }, [openO]);
    const [stockData1, setStockData1] = useState([]);
    const [count, setCount] = useState(0);

    const handleOnChange = async (e) => {
        let value = e.target.value;
        console.log(e.target.value);
        if (treatmentList) {
            if (value.length >= 2) {
                let search = await arraySearch(treatmentList, value);
                setTreatmentList(search)
                console.log(search);
                setCount(search.length)

            } else {
                setTreatmentList(stockData1)
                setCount(stockData1.length)
            }
        }
    }

    const [updateTreatment, setUpdateTreatment] = useState(-1);


    const [modalLoader, setModalLoader] = useState(false);

    // const nurseList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
        loader && loader1 && loader2 ?
            <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
            </div >
            :
            <>
                <Grid className='dashboard' container alignItems='flex-start' style={{ height: 'auto', paddingTop: '1em', paddingBottom: '2%' }} justifyContent='center'>


                    <Grid item style={{ marginLeft: width > 1079 ? width > 1250 ? '27%' : '38%' : '0px' }} lg={8} md={11} sm={11} xs={11}>
                        {width <= 600 ?
                            <><br /><br /></> : <></>}



                        <h2>Patient Details</h2>

                        {patientDetails != undefined ? <PatientDetailsCard patient={patientDetails} profileImg={Dummy} /> :
                            <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                            </div >}
                        <br />

                        <Grid container >

                            <Grid style={{ gap: 15, border: '10px solid transparent' }} container direction={'row'} item lg={6} md={6} sm={12} xs={12}>
                                <Grid conatiner item lg={12} md={12} sm={12} xs={12} className='commonCard'>
                                    <Grid container alignItems={'flex-start'} justifyContent={'space-between'}>
                                        <span style={{ fontWeight: '500', fontSize: '25px' }}>Observations</span>

                                    </Grid>
                                    <Grid style={{ flexWrap: 'nowrap', height: '16em', padding: '0.5em', overflowX: 'hidden', overflowY: 'scroll', gap: 15, marginTop: '1em' }} container direction={'column'}>

                                        <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5 }} className='commonCard' container alignItems={'flex-start'} justifyContent={'flex-start'}>
                                            <button className='commonButton' size='small'
                                                style={{ opacity: (!takeObservation[0]) ? '0.5' : '1', cursor: (takeObservation[0]) ? 'pointer' : 'not-allowed' }}
                                                onClick={takeObservation[0] ? handleOpenT : null} >Add Observations</button>
                                            <span style={{ fontWeight: '500', fontSize: 'small', color: 'rgb(126 126 126)' }}>Take this observation before 12 : 59 </span>

                                        </Grid>
                                        <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5 }} className='commonCard' container alignItems={'flex-start'} justifyContent={'flex-start'}>
                                            <button className='commonButton'
                                                onClick={takeObservation[1] ? handleOpenT : null}
                                                style={{ opacity: (!takeObservation[1]) ? '0.5' : '1', cursor: (takeObservation[1]) ? 'pointer' : 'not-allowed' }} size='small'  >Add Observations</button>
                                            <span style={{ fontWeight: '500', fontSize: 'small', color: 'rgb(126 126 126)' }}>Take this observation before 18 : 59</span>
                                        </Grid>
                                        <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5 }} className='commonCard' container alignItems={'flex-start'} justifyContent={'flex-start'}>
                                            <button className='commonButton'
                                                onClick={takeObservation[2] ? handleOpenT : null}
                                                style={{ opacity: (!takeObservation[2]) ? '0.5' : '1', cursor: (takeObservation[2]) ? 'pointer' : 'not-allowed' }} size='small'  >Add Observations</button>
                                            <span style={{ fontWeight: '500', fontSize: 'small', color: 'rgb(126 126 126)' }}>Take this observation before 23 : 59</span>
                                        </Grid>


                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid style={{ border: '10px solid transparent' }} container item lg={6} md={6} sm={12} xs={12}>
                                {doctorDetails != undefined ? <DocInfoCard doctor={doctorDetails} /> :
                                    <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                                        <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                    </div >}
                            </Grid>

                            <Grid style={{ border: '10px solid transparent' }} container item lg={12} md={12} sm={12} xs={12}>
                                <Grid container className='commonCard' >


                                    <br />
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item lg={6} md={6}>
                                            <span style={{ fontWeight: '500', fontSize: '25px' }}>Treatment Details</span>
                                        </Grid>

                                        <Grid item lg={6} md={6} >
                                            <TextField
                                                className='inputField'
                                                placeholder='Search'
                                                variant='outlined'
                                                type='text'


                                                onChange={handleOnChange}

                                            />
                                        </Grid>


                                    </Grid>
                                    <TableContainer component={Paper} style={{ marginTop: '1em' }}>
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">Sr.</StyledTableCell>

                                                    <StyledTableCell>Treatment/Medication</StyledTableCell>
                                                    <StyledTableCell align="center">Time</StyledTableCell>
                                                    <StyledTableCell align="center">Additional Info</StyledTableCell>
                                                    <StyledTableCell align="center">Status</StyledTableCell>
                                                    <StyledTableCell align="center">By Nurse</StyledTableCell>

                                                    <StyledTableCell align="center">Edit</StyledTableCell>
                                                </TableRow>
                                            </TableHead>

                                            {treatmentList == undefined ?
                                                <div style={{ display: 'flex', minHeight: '10vh', alignItems: 'center', justifyContent: 'center' }} >
                                                    <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                                </div > :
                                                treatmentList.length == 0 ? <h3>No Treatment</h3> :
                                                    <TableBody>
                                                        {treatmentList.map((row, idx) => (
                                                            <StyledTableRow key={row.idx}>
                                                                <StyledTableCell align="center">{idx + 1}</StyledTableCell>

                                                                <StyledTableCell >
                                                                    {row.treatment}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center">{row.treatment_time.replace('T', ' ').slice(0, -1)}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.details}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.status ? <CheckCircleOutlineIcon color={"success"} /> : <CancelIcon color="error" />}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.nurse_name}</StyledTableCell>

                                                                <StyledTableCell align="center">
                                                                    {row.status ? <></> : <EditIcon style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            // setIsUpdateTreatment(row.id);
                                                                            // settreatmentData({ treat: row.treatment, time: row.treatment_time.replace('T', ' ').slice(0, -1), addinfo: row.details })
                                                                            setOpenO(true);
                                                                            setUpdateTreatment(row.id);
                                                                        }}

                                                                    />
                                                                    }
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>

                                            }
                                        </Table>
                                    </TableContainer>




                                </Grid>




                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>


                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openT}
                    onClose={handleCloseT}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openT}>
                        <Box sx={styleT}>
                            <form onSubmit={e => e.preventDefault()}>
                                <Grid container direction='row' spacing={2}>
                                    <Grid spacing={2} container item lg={12} md={12} sm={12} xs={12} direction='row'>

                                        <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                            <span className='label'>Temperature</span>
                                            <TextField
                                                className='inputField'
                                                placeholder='34'
                                                variant='outlined'
                                                type='number'
                                                error={tempError}
                                                value={patientData.temp}

                                                onChange={(event) => {
                                                    settempError(false);
                                                    settempErrorText('');
                                                    setpatientData({ ...patientData, temp: event.target.value })
                                                }}

                                            />
                                            <FormHelperText style={{ color: 'red' }}>{tempErrorText}</FormHelperText>

                                        </Grid>
                                        <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                            <span className='label'>Blood Pressure</span>
                                            <TextField
                                                className='inputField'
                                                placeholder='120/90'
                                                variant='outlined'
                                                type='number'
                                                error={bpError}
                                                value={patientData.bp}
                                                onChange={(event) => {

                                                    setbpError(false);
                                                    setbpErrorText('');
                                                    if (event.target.value.length > 7) {
                                                        setbpError(true);
                                                        setbpErrorText('Please enter correct blood pressure');
                                                    }
                                                    setpatientData({ ...patientData, bp: event.target.value })
                                                }}

                                            />
                                            <FormHelperText style={{ color: 'red' }}>{bpErrorText}</FormHelperText>

                                        </Grid>



                                        <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                            <span className='label'>Oxymeter</span>
                                            <TextField
                                                className='inputField'
                                                placeholder='90'
                                                variant='outlined'
                                                type='number'
                                                error={oxymeterError}
                                                value={patientData.oxymeter}
                                                onChange={(event) => {

                                                    setoxymeterError(false);
                                                    setoxymeterErrorText('');
                                                    if (event.target.value.length > 7) {
                                                        oxymeterError(true);
                                                        setoxymeterErrorText('Please enter correct Oxygen level');
                                                    }
                                                    setpatientData({ ...patientData, oxymeter: event.target.value })
                                                }}

                                            />
                                            <FormHelperText style={{ color: 'red' }}>{oxymeterErrorText}</FormHelperText>

                                        </Grid>

                                        <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                            <span className='label'>Pulse</span>
                                            <TextField
                                                className='inputField'
                                                placeholder='34'
                                                variant='outlined'

                                                type='number'
                                                error={pulseError}
                                                value={patientData.pulse}

                                                onChange={(event) => {
                                                    setpulseError(false);
                                                    setpulseErrorText('');
                                                    setpatientData({ ...patientData, pulse: event.target.value })
                                                }}

                                            />
                                            <FormHelperText style={{ color: 'red' }}>{pulseErrorText}</FormHelperText>

                                        </Grid>

                                        <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                            <span className='label'>Comments</span>
                                            <TextField
                                                className='inputField'
                                                placeholder='34'
                                                variant='outlined'
                                                multiline
                                                rows={4}
                                                maxRows={6}
                                                type='text'


                                                onChange={(event) => {

                                                    setpatientData({ ...patientData, comments: event.target.value })
                                                }}

                                            />

                                        </Grid>

                                        <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                            {modalLoader ?

                                                <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                                                    <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                                </div >
                                                : <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ fontSize: '20px', color: themeMain.palette.primary.main, background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 60px' }} className='demoSubmitButton'
                                                    variant="contained"
                                                    onClick={() => {
                                                        console.log(patientData);
                                                        if (validateData()) {
                                                            console.log("Validate Success");
                                                            // console.log(patientData);
                                                            setModalLoader(true);
                                                            axiosInstance.post('/nurse/observation/', {
                                                                patient_id: id,
                                                                temperature: patientData.temp,
                                                                blood_pressure: patientData.bp,
                                                                oxygen_level: patientData.oxymeter,
                                                                heart_rate: patientData.pulse,
                                                                comment: patientData.comments
                                                            }).then(res => {
                                                                console.log(res.data);
                                                                setModalLoader(false);
                                                                setOpenT(false);
                                                            }).catch(err => {
                                                                setModalLoader(false);
                                                                console.log(err);
                                                            });

                                                        }
                                                    }
                                                    }

                                                >Submit</Button></div>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>

                        </Box>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openO}
                    onClose={handleCloseO}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openO}>
                        <Box sx={styleO}>
                            <Typography>Have you completed the Treatment ?</Typography>
                            <br />
                            <Grid container direction='row' justifyContent="center">
                                <Grid item lg={2} md={2} sm={6} xs={6}>
                                    <Button onClick={() => {
                                        console.log("Yes " + updateTreatment);
                                        axiosInstance.post(`nurse/toggle_treatment/${updateTreatment}/`, { patient_id: id }).then(res => {
                                            console.log(res.data);
                                            handleCloseO();

                                        }).catch(err => {
                                            console.log(err);
                                        });
                                    }} variant='contained' color='success'>Yes</Button>
                                </Grid>
                                <Grid item lg={2} md={2} sm={6} xs={6}>
                                    <Button onClick={() => {
                                        handleCloseO();
                                    }} variant='contained' color='error'>No</Button>
                                </Grid>
                            </Grid>

                        </Box>
                    </Fade>
                </Modal>



            </>



    );
}
export default NursePatientDetails;