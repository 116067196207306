import { Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect,useState } from 'react'
import './style.css';
import AchievementItem from './achievementItem';
import icon1 from '../../assets/achivements_img/beds.png';
import icon2 from '../../assets/achivements_img/doctor.png';
import icon3 from '../../assets/achivements_img/nurse.png';
import icon4 from '../../assets/achivements_img/patients.png';

/**
* @author
* @function Achievements
**/

const Achievements = (props) => {
    const [webHitCount, setWebHitCount] = useState('0');

   
    var achievementData=[
        {
            title:'Beds',
            titleCount:50,
            postTitleCount:'',
            icon:icon1
        },
        {
            title:'Doctors',
            titleCount:'25',
            postTitleCount:'+',
            icon:icon2
        }
        ,
        {
            title:'Nurses',
            titleCount:'20',
            postTitleCount:'+',
            icon:icon3
        },
        {
            title:'Patient Treated',
            titleCount:'500',
            postTitleCount:'+',
            icon:icon4
        }
    ];

    if(props.isStart){
        achievementData=[
            {
                title:'Website hits     ',
                titleCount:'0',
                postTitleCount:'',
                icon:icon1
            },
            {
                title:'Websites deployed',
                titleCount:'0',
                postTitleCount:'+',
                icon:icon2
            }
            ,
            {
                title:'Countries served',
                titleCount:'0',
                postTitleCount:'+',
                icon:icon3
            },
            {
                title:'Lines of code',
                titleCount:'0',
                postTitleCount:'M+',
                icon:icon4
            }
        ];
    }
    return (
        <Container style={{marginTop:'4em'}}>
            <Grid container justify='center'>
                {
                achievementData.map(achievement=>{
                    return <AchievementItem data={achievement}/>;
                })
                }
            </Grid>
        </Container>
    )

}

export default Achievements;