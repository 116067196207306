import {TextField,Button, CircularProgress, Fade, FormHelperText, Grid, Modal, Typography, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {Visibility,VisibilityOff} from "@mui/icons-material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Cookies from 'js-cookie';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import themeMain from '../../theme';
import { makeStyles } from '@material-ui/styles';
import { useWindowDimensions } from '../../utility/dimensions';
import { useNavigate } from 'react-router-dom';
import './style.css';
import axiosInstance from '../../utility/axios';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans',

        ].join(','),
    },
});


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    }
}));

const Login = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isError, setIsError] = useState(false);


    const { height, width } = useWindowDimensions();
    const [authData, setAuthData] = useState({
        password: '',
        email: '',
    })

    const history = useNavigate();
    const [loader, setloader] = React.useState(false);
    // const [ReCaptchaText, setReCaptchaText] = React.useState("");


    const [emailError, setemailError] = React.useState(false);
    const [emailErrorText, setemailErrorText] = React.useState("");

    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorText, setPasswordErrorText] = React.useState("");

    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validateData = () => {


        setemailError(ValidateSingleField(authData.email));
        ValidateSingleField(authData.email) ? setemailErrorText('Email cannot be blank') : setemailErrorText('');

        setPasswordError(ValidateSingleField(authData.password));
        ValidateSingleField(authData.password) ? setPasswordErrorText('Password cannot be blank') : setPasswordErrorText('');
        if (authData.email) {
            setemailError(!validateEmail(authData.email));
            setemailErrorText(!validateEmail(authData.email) ? 'Please enter correct email' : '');
        }

        if (validateEmail(authData.email) && !ValidateSingleField(authData.email) && !ValidateSingleField(authData.password))
            return true;



        return false;

    }
    const [values2, setValues2] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChangePassword = (prop) => (event) => {
        setPasswordError(false);
        setPasswordErrorText('');
        setValues2({ ...values2, [prop]: event.target.value });
        setAuthData({ ...authData, password: event.target.value });

    };
    const handleClickShowPassword = () => {
        setValues2({ ...values2, showPassword: !values2.showPassword });
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <ThemeProvider theme={theme}>
            <div style={{ position: 'relative', zIndex: '1' }}>
                <Grid container style={{ minHeight: '100vh' }}>
                   
                    <Grid style={{ display: 'flex', alignItems:'center', flexDirection: 'column', overflow: 'auto' }} item lg={12} md={12} sm={12} xs={12}>
                        <div style={{
                            width: '100%',
                            maxWidth: '33em'
                        }}>
                            <Grid style={{ padding: '3em 2.3em', minHeight: '100vh', color: '#fff', background: themeMain.palette.primary.main }} container direction='column' >
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center' }}>
                                    <Typography variant='h3'>Sign in</Typography>
                                    <span style={{ paddingTop: '10px' }}>Access to your dashboard </span>
                                    <br />

                                </div>

                                <br />
                                <br />
                                <br />
                                <form onSubmit={(e) => e.preventDefault()} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className='label'>Email address</span>
                                    <TextField
                                        className='inputField'
                                        placeholder='name@example.com'
                                        variant='outlined'
                                        type='email'

                                        error={emailError}
                                        value={authData.email}
                                        onChange={(event) => {
                                            setemailError(false);
                                            setemailErrorText('');
                                            setAuthData({ ...authData, email: event.target.value })
                                        }}

                                    />
                                    <FormHelperText style={{ color: 'red' }}>{emailErrorText}</FormHelperText>
                                    <br />

                                    <span className='label'>Password</span>
                                    <OutlinedInput
                                        className='inputField'
                                        placeholder='6 characters, 1 capital letter'
                                        variant='outlined'
                                        error={passwordError}
                                        type={values2.showPassword ? 'text' : 'password'}
                                        value={values2.password}
                                        onChange={handleChangePassword('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {values2.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText style={{ color: 'red' }}>{passwordErrorText}</FormHelperText>
                                    <br />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <span style={{ cursor: 'pointer', color: themeMain.palette.primary.light }}>Forgot Password ?</span>
                                    </div>
                                    <br />


                                    {/* <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '20px 0' }}>
                                    <ReCAPTCHA
                                        sitekey="6LdrTn8cAAAAADxkhpfTvTp_nVulm9D_8BH6_sBJ"
                                        stoken="6LdrTn8cAAAAADsdX_YanKa76kXsJ3VG4qowSt7o"
                                        onExpired={() => {
                                            setCaptcha(false);
                                        }}
                                        onChange={() => {
                                            setReCaptchaText("")
                                            setCaptcha(true);
                                        }}
                                    />
                                    <FormHelperText style={{ color: 'red' }}>{ReCaptchaText}</FormHelperText>
                                    </Grid> */}
                                    <br />
                                    {loader ? <div style={{ display: 'flex', justifyContent: 'center' }} >
                                        <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                    </div> : <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ color: themeMain.palette.primary.main, fontSize: '20px', background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 60px' }} className='demoSubmitButton'
                                        variant="contained"
                                        onClick={() => {
                                            if (validateData()) {
                                                setloader(true);

                                                axiosInstance.post('/auth/login/', {username:authData.email,password:authData.password}).then((res) => {
                                                    setloader(false);
                                                    setIsError(false);
                                                    setSnackbarMessage("Login Successfull");
                                                    setOpen(true);
                                                    Cookies.set('refresh_token', res.data.refresh);
                                                    Cookies.set('access_token', res.data.access);
                                                    Cookies.set('role', res.data.user);

                                                    window.location.replace(`/${res.data.user}-dashboard`);

                                                }).catch((err) => {
                                                    setIsError(true);
                                                    setSnackbarMessage("username or password incorrect");
                                                    setOpen(true);
                                                    setloader(false);

                                                });
                                            }
                                        }
                                        }
                                    >Submit</Button></div>}
                                </form>

                                <br />
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    <span style={{ color: '#9a9b9d' }}>Don't have an account yet ?</span>
                                    <span style={{ marginLeft: '10px', cursor: 'pointer', color: themeMain.palette.primary.light }}
                                        onClick={() => {
                                            navigate('/patient-register');
                                        }}> Sign up here</span>
                                </div>



                            </Grid>

                        </div>
                        <Snackbar open={open} autoHideDuration={2000} onClose={() => {
                            setOpen(false);
                        }}>
                            <Alert onClose={() => {
                                setOpen(false);
                            }} severity={isError ? "error" : "success"} sx={{ width: '100%' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Grid>

                </Grid>
            </div>

        </ThemeProvider>
    );
}
export default Login;