import { Box, Container, FormControl, FormHelperText, FormLabel, Grid, Input, InputLabel, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import './style.css';
//import ReCAPTCHA from "react-google-recaptcha";

//import { execApi } from '../../lib/apiProvider';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Tick from '../../assets/tickGIF.gif';
/**
* @author
* @function ContactUs
**/
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width:'80%',
        maxWidth:'25em'
    },
}));


const ContactUs = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setX(<div class="lds-ring"><div></div><div></div><div></div><div></div></div>);
        setContactUsData({
            name: '',
            email: '',
            message: ''
        });
    };

    const [contactUsData, setContactUsData] = useState({
        name: '',
        email: '',
        message: ''
    })

    //const [captcha, setCaptcha] = useState(false);

    // const sendData = async () => {
    //     const res = await execApi('/contactus/', { contactUsData });
    //     return res;
    // }

    const [x, setX] = useState(<div  class="lds-ring"><div></div><div></div><div></div><div></div></div>);
    return (
        <>
            <Grid container justify='center' flexDirection='column' alignItems='center' >
                <div className="contactusformContainer">
                    <form className="contactusform" method='post' onSubmit={async (event) => {
                        event.preventDefault();
                        
                            // console.log(contactUsData);

                            handleOpen();
                            //const res = sendData();
                            
                                setX(<div style={{borderRadius:'25px'}} className={classes.paper}>
                                    <img style={{ width: '50%' }} src={Tick} />
                                    <h2 style={{textAlign:'center'}}  id="transition-modal-title">Thanks for contacting us</h2>
                                    <p style={{textAlign:'center'}} id="transition-modal-description">We have recieved your query.We'll get back to you as soon as possible</p>
                                </div>);


                            


                        
                    }}>
                        <div style={
                            {
                                fontWeight: '600',
                                fontSize: '1.5em',
                                padding: '0.5em',
                                textAlign: 'center',
                            }}>
                            <label htmlFor="title" >Contact form</label>
                        </div>
                        <Box p={1}>
                            <div>
                                <label htmlFor="name">Your name:</label>
                            </div>
                            <div>
                                <input type="text" name="name" value={contactUsData.name} onChange={(event) => setContactUsData({ ...contactUsData, name: event.target.value })} id="name" required />
                            </div>
                        </Box>
                        <Box p={1}>
                            <div>
                                <label htmlFor="email">Email:</label>
                            </div>
                            <div>
                                <input type="email" name="email" value={contactUsData.email} onChange={(event) => setContactUsData({ ...contactUsData, email: event.target.value })} id="email" required />
                            </div>
                        </Box>
                        <Box p={1}>
                            <div>
                                <label htmlFor="message">Message:</label>
                            </div>
                            <div>
                                <textarea name="message" value={contactUsData.message} onChange={(event) => setContactUsData({ ...contactUsData, message: event.target.value })} maxLength='300' id="message" required />
                            </div>
                        </Box>
                        <Box p={1}>
                            {/* <ReCAPTCHA
                                sitekey="6Ld-FqcaAAAAAJvI2nX2dPxPg8iHrNf7f98B6oAv"
                                onChange={() => {
                                    setCaptcha(true);
                                }}
                            /> */}
                            <button type='submit' style={{cursor:'pointer'}} value='submit'>Reach us</button>
                        </Box>
                    </form>
                </div>

            </Grid>
            <Modal
                
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    {x}
                </Fade>
            </Modal>
        </>
    )

}

export default ContactUs;