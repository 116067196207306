import React,{useState} from 'react';
import './style.css';
import Logo from '../../assets/logo.png';
import HiIcons from './hamburger.svg';
import { NavLink } from 'react-router-dom';
import CloseIcon from './closeIcon.svg';
import { Typography } from '@material-ui/core';
/**
* @author
* @function NavBar
**/

const NavBar = (props) => {
    const [navVisible,setNavVisible]=useState(false);

    var navCss='menu';
    if(navVisible){
        navCss='navOpen';
    }

    const navClose=()=>{
        setNavVisible(false);
    }
    return (
        <header class="header">
            <a href="/" class="logo"> 
           <Typography style={{fontWeight:'600',fontSize:'25px'}} className="tagLine">Aarogya Doot</Typography>
            </a>          
            <label className="menu-icon" onClick={()=>{
                setNavVisible(!navVisible);
            }} for="menu-btn"><img src={HiIcons}/></label>
            
            <ul className={navCss}>
                <li><NavLink onClick={navClose} activeClassName="selected" to="/doctor-list">Doctors</NavLink></li>
                <li><NavLink onClick={navClose} activeClassName="selected" to="/about">About us</NavLink></li>
                <li><NavLink onClick={navClose} activeClassName="selected" to="/contact">Contact us</NavLink></li>
                <li><NavLink onClick={navClose} activeClassName="selected" to="/login">Login</NavLink></li>

                <li><img src={CloseIcon} className='closeIcon' onClick={navClose}/></li>
            </ul>
        </header>
    )

}

export default NavBar