import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../utility/dimensions';
import { FormHelperText } from '@mui/material';
import '../../../commonStyle.css';
import Dummy from '../../../assets/logo5.svg';
import PatientDetailsCard from '../../../components/PatientDetailsCard';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import themeMain from '../../../theme';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../utility/axios';
import CancelIcon from '@mui/icons-material/Cancel';
//Treatment Modal
const styleT = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    width: '60%',
    color: '#fff',
    bgcolor: '#012764',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

//Obervation Modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '60%',

    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

//Treatment table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: '600'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: '600'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));





const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase()
    return array.filter(value => {
        return value.nurse_name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.treatment.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.treatment_time.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.details.toLowerCase().match(new RegExp(searchTerm, 'g'))

    })
}


const PatientDetails = (props) => {

    //Treatment form
    //Form data
    const [treatmentData, settreatmentData] = useState({

        treat: '',
        time: '',
        addinfo: ''


    })

    const [editTreatmentData, seteditTreatmentData] = useState({

        treatment: '',
        time: '',
        addInfo: ''


    })



    const { id } = useParams();
    // Observation Modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Treatment Modal
    const [openT, setOpenT] = React.useState(false);
    const handleOpenT = (id) =>{
        if(id==-1)
        {
            settreatmentData({
                treat:'',
                time:'',
                addinfo:''
            })
        }
        setOpenT(true);
    }
    const handleCloseT = () => {
        setIsUpdateTreatment(-1);
        setOpenT(false);
    }
    // Edit Treatment Modal
    const [openTE, setOpenTE] = React.useState(false);
    const handleOpenTE = () => setOpenTE(true);
    const handleCloseTE = () => setOpenTE(false);

    const [treatError, settreatError] = React.useState(false);
    const [treatErrorText, settreatErrorText] = React.useState("");

    const [timeError, settimeError] = React.useState(false);
    const [timeErrorText, settimeErrorText] = React.useState("");

    const [addinfoError, setaddinfoError] = React.useState(false);
    const [addinfoErrorText, setaddinfoErrorText] = React.useState("");

    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const { height, width } = useWindowDimensions();
    const [date, setDate] = React.useState(new Date());
    const patient = {
        name: 'Priyansh Sunilkumar Solanki',
        age: '22',
        bloodgroup: 'B+',
        contact: '976543210',
        gender: 'Male',
        ename: 'Jayanth Pabothu',
        econtact: '987654321',
        roomno: '341',
        bedno: '21'
    }
    // const nurseList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];



    const validateData = () => {
        settreatError(ValidateSingleField(treatmentData.treat));
        ValidateSingleField(treatmentData.treat) ? settreatErrorText('Medication cannot be blank') : settreatErrorText('');

        settimeError(ValidateSingleField(treatmentData.time));
        ValidateSingleField(treatmentData.time) ? settimeErrorText('Medication time cannot be blank') : settimeErrorText('');

        // setaddinfoError(ValidateSingleField(treatmentData.addinfo));
        // ValidateSingleField(treatmentData.addinfo) ? setaddinfoErrorText('cannot be blank') : setaddinfoErrorText('');




        if (
            !ValidateSingleField(treatmentData.treat) &&
            !ValidateSingleField(treatmentData.time)


        ) {


            return true;


        }
        return false;


    }
    const [patientDetails, setPatientDetails] = useState();
    const [loader1, setLoader1] = useState(true);
    React.useEffect(() => {

        axiosInstance.get(`/patient/patient_details/${id}/`).then(res => {
            setLoader1(false);
            setPatientDetails(res.data);

        }).catch(err => {
            setLoader1(false);
        });
    }, []);
    const [addTreatmentLoader, setAddTreatmentLoader] = useState(false);


    const [treatmentList, setTreatmentList] = useState();
    React.useEffect(() => {

        axiosInstance.get(`/doctor/treatment/?patient_id=${id}`).then(res => {

            res.data.map(o => {
                if (o.completed_at == null)
                    o.completed_at = '';
                if (o.created_at == null)
                    o.created_at = '';
                if (o.details == null)
                    o.details = '';
                if (o.nurse_name == null)
                    o.nurse_name = '';
                if (o.treatment == null)
                    o.treatment = '';
                if (o.treatment_time == null)
                    o.treatment_time = '';
            });
            let tmp = res.data.reverse();

            setTreatmentList(tmp);
            setStockData1(tmp);
            console.log(res.data);
        }).catch(err => {
        });
    }, [openT]);


    const [observationList, setObservationList] = useState();
    React.useEffect(() => {

        axiosInstance.get(`/doctor/observation/?patient_id=${id}`).then(res => {
            let tmp = res.data.reverse();

            setObservationList(tmp);

            console.log(res.data);
        }).catch(err => {
        });
    }, []);
    const [nurseList, setNurseList] = useState();
    React.useEffect(() => {

        axiosInstance.get(`/nurse/operated_nurse_list/?patient_id=${id}`).then(res => {
            let tmp = res.data.reverse();

            setNurseList(tmp);

            console.log(res.data);
        }).catch(err => {
        });
    }, []);
    

    const [stockData1, setStockData1] = useState([]);
    const [count, setCount] = useState(0);

    const handleOnChange = async (e) => {
        let value = e.target.value;
        console.log(e.target.value);
        if (treatmentList) {
            if (value.length >= 2) {
                let search = await arraySearch(treatmentList, value);
                setTreatmentList(search)
                console.log(search);
                setCount(search.length)

            } else {
                setTreatmentList(stockData1)
                setCount(stockData1.length)
            }
        }
    }

    const [currentObservation, setCurrentObservation] = useState({
        blood_pressure: "90mm",
        comment: "Take on time",
        completed_at: null,
        created_at: "2022-05-28T07:27:42.496821Z",
        heart_rate: "60",
        id: 1,
        nurse: null,
        nurse_name: "",
        oxygen_level: "98",
        patient: 1,
        status: false,
        temperature: "98 C"
    });


    const [isUpdateTreatment, setIsUpdateTreatment] = useState();

    return (
        <>
            <Grid className='dashboard' container alignItems='flex-start' style={{ height: 'auto', paddingTop: '1em', paddingBottom: '2%' }} justifyContent='center'>


                <Grid item style={{ marginLeft: width > 1079 ? width > 1250 ? '27%' : '38%' : '0px' }} lg={8} md={11} sm={11} xs={11}>
                    {width <= 600 ?
                        <><br /><br /></> : <></>}



                    <h2>Patient Details</h2>


                    {patientDetails != undefined ? <PatientDetailsCard patient={patientDetails} profileImg={Dummy} /> :
                        <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                            <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                        </div >}
                    <br />


                    <Grid container >

                        <Grid style={{ gap: 15, border: '10px solid transparent' }} container direction={'row'} item lg={6} md={6} sm={12} xs={12}>
                            <Grid conatiner item lg={12} md={12} sm={12} xs={12} className='commonCard'>
                                <Grid container alignItems={'flex-start'} justifyContent={'space-between'}>
                                    <span style={{ fontWeight: '500', fontSize: '25px' }}>Observations</span>
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker

                                            renderInput={(props) => <TextField size={'small'} {...props} />}
                                            label="DateTimePicker"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider> */}
                                </Grid>
                                <Grid style={{ flexWrap: 'nowrap', height: '16em', padding: '0.5em', overflowX: 'hidden', overflowY: 'scroll', gap: 15, marginTop: '1em' }} container direction={'column'}>

                                    {
                                        observationList == undefined ?
                                            <div style={{ display: 'flex', minHeight: '10vh', alignItems: 'center', justifyContent: 'center' }} >
                                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                            </div >
                                            :
                                            observationList.length == 0 ? <h3>No Observations yet</h3> :
                                                observationList.map(observation => {
                                                    return (
                                                        <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5 }} className='commonCard' container alignItems={'flex-start'} justifyContent={'flex-start'}>
                                                            <button className='commonButton' size='small' onClick={() => {
                                                                handleOpen();
                                                                setCurrentObservation(observation);
                                                            }}  >View Observations</button>
                                                            <span style={{ fontWeight: '500', fontSize: 'small', color: 'rgb(126 126 126)' }}>{(observation.created_at.replace('T', ' ')).slice(0, -8)}</span>
                                                        </Grid>
                                                    );
                                                })
                                    }


                                    {/* <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5 }} className='commonCard' container alignItems={'flex-start'} justifyContent={'flex-start'}>
                                        <button className='commonButton' size='small'  >View Observations</button>
                                        <span style={{ fontWeight: '500', fontSize: 'small', color: 'rgb(126 126 126)' }}>{new Date().toString()}</span>
                                    </Grid>
                                    <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5 }} className='commonCard' container alignItems={'flex-start'} justifyContent={'flex-start'}>
                                        <button className='commonButton' size='small'  >View Observations</button>
                                        <span style={{ fontWeight: '500', fontSize: 'small', color: 'rgb(126 126 126)' }}>{new Date().toString()}</span>
                                    </Grid> */}


                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid style={{ border: '10px solid transparent' }} container item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className='commonCard' >

                                <span style={{ fontWeight: '500', fontSize: '25px' }}>Nurses operated</span>

                                <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5, height: '15em', flexWrap: 'nowrap', overflowY: 'scroll' }} className='commonCard' direction={'column'} container alignItems={'flex-start'} justifyContent={'flex-start'}>
                                    {
                                        nurseList==undefined ? <></>:
                                        nurseList.map((nurse, index) => <Typography variant='h5' >{index + 1}. {nurse.full_name}</Typography>)
                                    }
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid style={{ border: '10px solid transparent' }} container item lg={12} md={12} sm={12} xs={12}>
                            <Grid container className='commonCard' >


                                <br />
                                <Grid container justifyContent={'space-between'} direction="row" spacing={1}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <span style={{ fontWeight: '500', fontSize: '25px' }}>Treatment Details</span>
                                    </Grid>
                                    {/* <Grid item container lg={12} direction="row" justifyContent="space-between"  ></Grid> */}
                                    <Grid item lg={6} md={6} >
                                        <TextField
                                            className='inputField'
                                            placeholder='Search'
                                            variant='outlined'
                                            type='text'


                                            onChange={handleOnChange}

                                        />
                                    </Grid>
                                    {/* <Grid item lg={5} md={5}>

                                        <TextField
                                            className='inputField'

                                            variant='outlined'

                                            type='date'


                                            onChange={(event) => {

                                            }}

                                        />
                                    </Grid> */}

                                </Grid>
                                <TableContainer component={Paper} style={{ marginTop: '1em' }}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">Sr.</StyledTableCell>

                                                <StyledTableCell>Treatment/Medication</StyledTableCell>
                                                <StyledTableCell align="center">Time</StyledTableCell>
                                                <StyledTableCell align="center">Additional Info</StyledTableCell>
                                                <StyledTableCell align="center">Status</StyledTableCell>
                                                <StyledTableCell align="center">By Nurse</StyledTableCell>

                                                <StyledTableCell align="center">Edit</StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                        {treatmentList == undefined ?
                                            <div style={{ display: 'flex', minHeight: '10vh', alignItems: 'center', justifyContent: 'center' }} >
                                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                            </div > :
                                            treatmentList.length == 0 ? <h3>No Treatment</h3> :
                                                <TableBody>
                                                    {treatmentList.map((row, idx) => (
                                                        <StyledTableRow key={row.idx}>
                                                            <StyledTableCell align="center">{idx + 1}</StyledTableCell>

                                                            <StyledTableCell >
                                                                {row.treatment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{row.treatment_time.replace('T', ' ').slice(0, -1)}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.details}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.status ? <CheckCircleOutlineIcon color={"success"} /> : <CancelIcon color="error" />}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.nurse_name}</StyledTableCell>

                                                            <StyledTableCell align="center">
                                                                <EditIcon style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setIsUpdateTreatment(row.id);
                                                                        settreatmentData({ treat: row.treatment, time: row.treatment_time.replace('T', ' ').slice(0, -1), addinfo: row.details })
                                                                        handleOpenT(row.id);
                                                                    }}
                                                                />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>

                                        }
                                    </Table>
                                </TableContainer>

                                <Grid item lg={12} style={{ marginTop: '1em' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ fontSize: '20px', color: themeMain.palette.primary.main, background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 50px' }}
                                        variant="contained"
                                        onClick={()=>handleOpenT(-1)}
                                    >Add Treatment</Button></div>
                                </Grid>


                            </Grid>




                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
            {/* Observation modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div style={{ margin: '0.5em' }}>



                            <br />
                            <Grid container spacing={3} >

                                <Grid lg={12} sm={12} xs={12} >

                                    <Typography style={{ fontWeight: '700', fontSize: '20px', marginBottom: '1em' }}>Nurse: {currentObservation.nurse_name}</Typography>


                                </Grid>

                                <Grid lg={3} sm={12} xs={12} >

                                    <Typography style={{ fontWeight: '600' }}>Temp</Typography>
                                    <Typography>{currentObservation.temperature}</Typography>


                                </Grid>
                                <Grid lg={3} sm={12} xs={12} >
                                    <Typography style={{ fontWeight: '600' }}>B.P</Typography>
                                    <Typography>{currentObservation.blood_pressure}/90</Typography>
                                </Grid>
                                <Grid lg={3} sm={12} xs={12}>
                                    <Typography style={{ fontWeight: '600' }}>O.L</Typography>
                                    <Typography>{currentObservation.oxygen_level}</Typography>
                                </Grid>
                                <Grid lg={3} sm={12} xs={12} >
                                    <Typography style={{ fontWeight: '600' }}>H.R</Typography>
                                    <Typography>{currentObservation.heart_rate} bpm</Typography>
                                </Grid>
                                <Grid lg={12} sm={12} xs={12} >
                                    <Typography style={{ fontWeight: '600', marginTop: '0.5em' }}>Comment</Typography>
                                    <div style={{ borderStyle: 'solid', borderColor: '#000', height: '10em' }}>
                                        <Typography variant='body1'>{currentObservation.comment}</Typography>
                                    </div>

                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </Fade>
            </Modal>


            {/* Treatment Modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openT}
                onClose={handleCloseT}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openT}>
                    <Box sx={styleT}>
                        <form onSubmit={e => e.preventDefault()}>
                            <Grid container direction='row' spacing={2}>
                                <Grid spacing={2} container item lg={12} md={12} sm={12} xs={12} direction='row'>

                                    <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                        <span className='label'>Treatment/Medication</span>
                                        <TextField
                                            className='inputField'
                                            placeholder="Ringer's lactate solution"
                                            variant='outlined'
                                            type='text'
                                            error={treatError}
                                            value={treatmentData.treat}

                                            onChange={(event) => {
                                                settreatError(false);
                                                settreatErrorText('');
                                                settreatmentData({ ...treatmentData, treat: event.target.value })
                                            }}

                                        />
                                        {<FormHelperText style={{ color: 'red' }}>{treatErrorText}</FormHelperText>}

                                    </Grid>
                                    <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                        <span className='label'>At what time</span>
                                        <TextField
                                            className='inputField'
                                            placeholder='12am-2am'
                                            variant='outlined'
                                            type='datetime-local'
                                            error={timeError}
                                            value={treatmentData.time}
                                            onChange={(event) => {

                                                settimeError(false);
                                                settimeErrorText('');


                                                settreatmentData({ ...treatmentData, time: event.target.value })
                                            }}

                                        />
                                        <FormHelperText style={{ color: 'red' }}>{timeErrorText}</FormHelperText>

                                    </Grid>




                                    <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                        <span className='label'>Additional Info</span>
                                        <TextField
                                            className='inputField'
                                            placeholder='Give it in afternoon, after lunch'
                                            variant='outlined'
                                            multiline
                                            rows={4}
                                            maxRows={6}
                                            type='text'
                                            error={addinfoError}
                                            value={treatmentData.addinfo}

                                            onChange={(event) => {
                                                setaddinfoError(false);
                                                setaddinfoErrorText('');
                                                settreatmentData({ ...treatmentData, addinfo: event.target.value })
                                            }}

                                        />
                                        <FormHelperText style={{ color: 'red' }}>{addinfoErrorText}</FormHelperText>

                                    </Grid>









                                    <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                        {addTreatmentLoader ?
                                            <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                            </div > : <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ fontSize: '20px', color: themeMain.palette.primary.main, background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 60px' }} className='demoSubmitButton'
                                                variant="contained"
                                                onClick={() => {
                                                    console.log(treatmentData);
                                                    if (validateData()) {
                                                        console.log("Validate Success");
                                                        // var tTmp = (treatmentData.time.replace('T', ' ') + ":00");
                                                        // console.log(tTmp);
                                                        setAddTreatmentLoader(true);

                                                        if (isUpdateTreatment != -1) {
                                                            console.log("Update");
                                                            console.log(isUpdateTreatment);
                                                            axiosInstance.put('/doctor/treatment/', {
                                                                treatment_id: isUpdateTreatment,
                                                                treatment: treatmentData.treat,
                                                                details: treatmentData.addinfo,
                                                                treatment_time: treatmentData.time
                                                            }).then(res => {
                                                                handleCloseT();
                                                                setAddTreatmentLoader(false);
                                                                settreatmentData({
                                                                    treat: '',
                                                                    time: '',
                                                                    addinfo: ''
                                                                });
                                                                console.log(res.data);
                                                            }).catch(err => {
                                                                console.log(err.response);
                                                                setAddTreatmentLoader(false);

                                                            })
                                                        }
                                                        else {
                                                            axiosInstance.post('/doctor/treatment/', {
                                                                patient_id: id,
                                                                treatment: treatmentData.treat,
                                                                details: treatmentData.addinfo,
                                                                treatment_time: treatmentData.time
                                                            }).then(res => {
                                                                handleCloseT();
                                                                setAddTreatmentLoader(false);
                                                                settreatmentData({
                                                                    treat: '',
                                                                    time: '',
                                                                    addinfo: ''

                                                                });
                                                                console.log(res.data);
                                                            }).catch(err => {
                                                                setAddTreatmentLoader(false);

                                                            })
                                                        }
                                                    }
                                                }

                                                }

                                            >Submit</Button></div>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>

                    </Box>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openTE}
                onClose={handleCloseTE}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openTE}>
                    <Box sx={styleT}>
                        <form onSubmit={e => e.preventDefault()}>
                            <Grid container direction='row' spacing={2}>
                                <Grid spacing={2} container item lg={12} md={12} sm={12} xs={12} direction='row'>

                                    <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                        <span className='label'>Treatment/Medication</span>
                                        <TextField
                                            className='inputField'

                                            variant='outlined'
                                            type='text'
                                            value={editTreatmentData.treatment}
                                        // error={tempError}


                                        // onChange={(event) => {
                                        //     settempError(false);
                                        //     settempErrorText('');
                                        //     setpatientData({ ...patientData, temp: event.target.value })
                                        // }}

                                        />
                                        {/* <FormHelperText style={{ color: 'red' }}>{tempErrorText}</FormHelperText> */}

                                    </Grid>
                                    <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                        <span className='label'>At what time</span>
                                        <TextField
                                            className='inputField'

                                            variant='outlined'
                                            type='text'
                                            value={editTreatmentData.time}
                                        // error={bpError}

                                        // onChange={(event) => {

                                        //     setbpError(false);
                                        //     setbpErrorText('');
                                        //     if (event.target.value.length > 7) {
                                        //         setbpError(true);
                                        //         setbpErrorText('Please enter correct blood pressure');
                                        //     }
                                        //     setpatientData({ ...patientData, bp: event.target.value })
                                        // }}

                                        />
                                        {/* <FormHelperText style={{ color: 'red' }}>{bpErrorText}</FormHelperText> */}

                                    </Grid>




                                    <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                        <span className='label'>Additional Info</span>
                                        <TextField
                                            className='inputField'

                                            variant='outlined'
                                            multiline
                                            rows={4}
                                            maxRows={6}
                                            type='text'
                                            value={editTreatmentData.addInfo}
                                        // error={commentsError}


                                        // onChange={(event) => {
                                        //     setcommentsError(false);
                                        //     setcommentsErrorText('');
                                        //     setpatientData({ ...patientData, comments: event.target.value })
                                        // }}

                                        />
                                        {/* <FormHelperText style={{ color: 'red' }}>{commentsErrorText}</FormHelperText> */}

                                    </Grid>









                                    <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                        <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ fontSize: '20px', color: themeMain.palette.primary.main, background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 60px' }} className='demoSubmitButton'
                                            variant="contained"
                                            onClick={() => {
                                                handleCloseTE();
                                                // console.log(patientData);
                                                // if (validateData()) {
                                                //     console.log("Validate Success");
                                                //     console.log(patientData);
                                                // }
                                            }
                                            }

                                        >Submit</Button></div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>

                    </Box>
                </Fade>
            </Modal>

        </>



    );
}
export default PatientDetails;