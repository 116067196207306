import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import './style.css';
import PatientCard from '../../../components/PatientCard/index';
import { useWindowDimensions } from "../../../utility/dimensions";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../utility/axios";
import Cookies from "js-cookie";
const NurseDashboard = (props) => {

    const { height, width } = useWindowDimensions();
    const [loader, setLoader] = React.useState(true);
    const [patientData, setPatientData] = React.useState([]);
    const navigate = useNavigate();
    React.useEffect(() => {
        axiosInstance.get(`/patient/patient_list/`).then(res => {
            setLoader(false);
            console.log(res.data);
            setPatientData(res.data);
        }).catch(err => {
            setLoader(false);
            console.log(err.response)
        });
    }, []);
    return (

        <Grid className='dashboard' container alignItems='flex-start' style={{ height: 'auto', paddingTop: '1em', paddingBottom: '2%' }} justifyContent='center'>

            {loader ?
                <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                    <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                </div > :

                <Grid item style={{ marginLeft: width > 1079 ? width > 1250 ? '27%' : '38%' : '0px' }} lg={8} md={11} sm={11} xs={11}>
                    {width <= 600 ?
                        <><br /><br /></> : <></>}


                    <Grid container spacing={1} direction='row'>

                        {patientData.map(patientData => {
                            return  <Grid key={patientData.id} item container justifyContent='center' direction='column' alignItems='center' className='cards' xl={4} lg={6} md={6} sm={6} xs={12}>
                                <PatientCard onClick={() => {
                                    navigate(`/nurse-patient-details/${patientData.id}`);
                                }} name={patientData.full_name} temperature={patientData.temperature} address={patientData.address} bloodgroup={patientData.blood_group} gender={patientData.gender} />

                            </Grid>
                        })}

                    </Grid>
                </Grid>
            }
        </Grid>

    );
}
export default NurseDashboard;