import { Box, CircularProgress, Fade, Grid, Modal, FormHelperText, Button, TextField, Backdrop } from "@mui/material";
import React, { useState } from "react";
import themeMain from '../../theme';
import './style.css';
import { useWindowDimensions } from "../../utility/dimensions";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utility/axios";
import Cookies from "js-cookie";
import PatientCardUpload from "../../components/PatientCardUpload";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
const ShowPatientsForUpload = (props) => {

    const { height, width } = useWindowDimensions();
    const [loader, setLoader] = React.useState(true);
    const [patientData, setPatientData] = React.useState([]);
    const navigate = useNavigate();
    React.useEffect(() => {
        axiosInstance.get(`/patient/patient_list/`).then(res => {
            setLoader(false);
            console.log(res.data);
            setPatientData(res.data);
        }).catch(err => {
            setLoader(false);
            console.log(err.response)
        });
    }, []);
    const [getPatientReport, setGetPatientReport] = useState();
    const [reportListLoader, setReportListLoader] = useState(false);
    const [reportList, setReportList] = useState();
    React.useEffect(() => {
        console.log(getPatientReport);
        if (getPatientReport != undefined) {
            axiosInstance.get(`/patient/report/?patient_id=${getPatientReport}`).then(res => {
                setReportListLoader(false);
                console.log(res.data);
                setReportList(res.data.reverse());
            }).catch(err => {
                setLoader(false);
                console.log(err)
            });
        }
    }, [getPatientReport]);
    const styleT = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        color: '#fff',
        bgcolor: '#012764',
        border: '2px solid #fff',
        boxShadow: 24,
        p: 4,
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const [openT, setOpenT] = React.useState(false);
    const handleOpenT = () => setOpenT(true);
    const handleCloseT = () => setOpenT(false);
    const [openReportList, setOpenReportList] = React.useState(false);
    const handleOpenReportList = () => setOpenReportList(true);
    const handleCloseReportList = () => {
        setOpenReportList(false);
        if(reportList.length >0)
        setReportList();
    }
    const [reportName, setReportName] = useState('');
    const [reportNameError, setReportNameError] = useState(false);
    const [reportNameErrorText, setReportNameErrorText] = useState();

    const [reportFile, setReportFile] = useState(null);
    const [reportFileError, setReportFileError] = useState(false);
    const [reportFileErrorText, setReportFileErrorText] = useState();
    const [currPatientId, setCurrPatientId] = useState();
    const [modalLoader, setModalLoader] = useState(false);
    return (

        <Grid className='dashboard' container alignItems='flex-start' style={{ height: 'auto', paddingTop: '1em', paddingBottom: '2%' }} justifyContent='center'>

            {loader ?
                <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                    <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                </div > :

                <Grid item style={{ marginLeft: width > 1079 ? width > 1250 ? '27%' : '38%' : '0px' }} lg={8} md={11} sm={11} xs={11}>
                    {width <= 600 ?
                        <><br /><br /></> : <></>}

                     <h2>Reports / Documents</h2>   
                    <Grid container spacing={1} direction='row'>

                        {patientData.map(patientData => {
                            return <Grid key={patientData.id} item container justifyContent='center' direction='column' alignItems='center' className='cards' xl={4} lg={6} md={6} sm={6} xs={12}>
                                <PatientCardUpload onClickUpload={() => {
                                    setCurrPatientId(patientData.id);
                                    // navigate(`/nurse-patient-details/${patientData.id}`);

                                    handleOpenT();
                                }}
                                    onClickView={() => {

                                        setGetPatientReport(patientData.id);
                                        setOpenReportList(true);
                                        setReportListLoader(true);
                                    }}
                                    name={patientData.full_name} temperature={patientData.temperature} address={patientData.address} bloodgroup={patientData.blood_group} gender={patientData.gender} />

                            </Grid>
                        })}

                    </Grid>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openT}
                        onClose={handleCloseT}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openT}>
                            <Box sx={styleT}>
                                <form onSubmit={e => e.preventDefault()}>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid spacing={2} container item lg={12} md={12} sm={12} xs={12} direction='row'>

                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Report name</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='X-Ray'
                                                    variant='outlined'
                                                    type='text'
                                                    error={reportNameError}
                                                    value={reportName}

                                                    onChange={(event) => {
                                                        setReportNameError(false);
                                                        setReportNameErrorText('');
                                                        setReportName(event.target.value);
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{reportNameErrorText}</FormHelperText>

                                            </Grid>
                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Report file</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder=''
                                                    variant='outlined'
                                                    type='file'
                                                    error={reportFileError}

                                                    onChange={(event) => {
                                                        setReportFileError(false);
                                                        setReportFileErrorText('');
                                                        setReportFile(event.target.files[0]);
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{reportFileErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                {modalLoader ?

                                                    <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                                                        <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                                    </div >
                                                    : <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ fontSize: '20px', color: themeMain.palette.primary.main, background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 60px' }} className='demoSubmitButton'
                                                        variant="contained"
                                                        onClick={() => {
                                                            console.log("here");
                                                            let flag = 0;
                                                            if (reportName == "") {
                                                                setReportNameErrorText('Report name cannot be blank');
                                                                setReportNameError(true);
                                                                flag++;

                                                            }
                                                            if (reportFile.length > 0) {
                                                                setReportFileErrorText('Report file cannot be blank');
                                                                setReportFileError(true);
                                                                flag++;

                                                            }
                                                            if (flag == 0) {
                                                                console.log(reportName + " " + reportFile);
                                                                const data = new FormData();
                                                                data.append('report_file', reportFile)
                                                                data.append('report_name', reportName)
                                                                data.append('patient_id', currPatientId)
                                                                axiosInstance.post('/patient/report/', data).then(res => {
                                                                    console.log(res.data);
                                                                    setCurrPatientId(-1);
                                                                    setReportFile('');
                                                                    setReportName('');
                                                                    handleCloseT();

                                                                }).catch(err => {
                                                                    console.log(err);
                                                                })
                                                            }
                                                            // if (validateData()) {
                                                            //     console.log("Validate Success");
                                                            // console.log(patientData);
                                                            // setModalLoader(true);
                                                            // axiosInstance.post('/nurse/observation/', {
                                                            //     patient_id: id,
                                                            //     temperature: patientData.temp,
                                                            //     blood_pressure: patientData.bp,
                                                            //     oxygen_level: patientData.oxymeter,
                                                            //     heart_rate: patientData.pulse,
                                                            //     comment: patientData.comments
                                                            // }).then(res => {
                                                            //     console.log(res.data);
                                                            //     setModalLoader(false);
                                                            //     setOpenT(false);
                                                            // }).catch(err => {
                                                            //     setModalLoader(false);
                                                            //     console.log(err);
                                                            // });

                                                        }
                                                            // }
                                                        }

                                                    >Upload</Button></div>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>

                            </Box>
                        </Fade>
                    </Modal>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openReportList}
                        onClose={handleCloseReportList}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openReportList}>
                            <Box sx={styleT}>
                                <TableContainer style={{maxHeight:'50vh',overflowY:'scroll'}} component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Sr.No</StyledTableCell>
                                                <StyledTableCell align="left">Report Name</StyledTableCell>
                                                <StyledTableCell align="left">Upload time</StyledTableCell>
                                                <StyledTableCell align="left">Download</StyledTableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportList != undefined ?reportList.length==0? <h3>No reports uploaded yet</h3>: reportList.map((row, idx) => (
                                                <StyledTableRow key={row.id}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {idx + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">{row.report_name}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.created_at.slice(0,-13).replace('T',' ')}</StyledTableCell>
                                                    <StyledTableCell align="left"><a href={row.report_file} title='' target="_blank"><CloudDownloadIcon style={{cursor:'pointer'}} color='success' size='small'/></a></StyledTableCell>

                                                </StyledTableRow>
                                            )) : <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                            </div >}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Box>
                        </Fade>
                    </Modal>
                </Grid>
            }
        </Grid>

    );
}
export default ShowPatientsForUpload;