import React, { Suspense, lazy } from 'react';
import "./style.css";
import Slide from "react-reveal/Slide";

import { Box, Container, Grid, Typography } from '@material-ui/core';
// import Footer from '../../components/FooterContainer';
import Logo1 from '../../assets/icons_services/icon1.svg';
import Logo2 from '../../assets/icons_services/icon2.svg';
import Logo3 from '../../assets/icons_services/icon3.svg';
import Logo4 from '../../assets/icons_services/icon4.svg';
import ContentBlock from '../../components/ContentBlock';
import NavBar from '../../components/Navbar';
import Achievements from '../../components/Achievements';
import { useInView } from 'react-intersection-observer';
import DocCard from '../../components/DocCard';
import { PieChart, Pie, Sector, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, BarChart, Bar } from 'recharts';






// import Work from '../../components/WorkScroll';
// import Testimonials from '../../components/Testimonials';
// import Worlg from '../../components/WorlgMap';



//charts data


const pdata = [
    {
        name: 'Jan',
        Patients: 20,

    },
    {
        name: 'Feb',
        Patients: 30,

    },
    {
        name: 'March',
        Patients: 32,

    },
    {
        name: 'April',
        Patients: 27,

    },
    {
        name: 'May',
        Patients: 31,

    },
    {
        name: 'June',
        Patients: 25,

    },
];

const data = [
    { name: 'Beds Occupied', value: 300 },
    { name: 'Beds Available', value: 700 },

];

const COLORS = ['#0088FE', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const DocData = [
    {
        name: "Dr. Kamlesh Mishra",
        specialization: "Orthopedic",
        about: "He is a renowned Orthopedic Surgeon with keen interest in Joint Replacement and Arthroscopy Surgeries of Knee, Shoulder, and Hip. Before joining Sakra World Hospital.Presently."
    },
    {
        name: "Dr. Harvey Joseph",
        specialization: "General Physician",
        about: "He has extensive clinical experience in treating several medical and gynecologic conditions. In span of 3 decades of his acquired clinical skills , he has now extended to performing aestheti procedures like botox, derma fillers, and various other cosmetic procedures."
    },
    {
        name: "Dr. Sujay BR",
        specialization: "Laparoscopic Surgeon",
        about: "Dr. Sujay B R is an experienced General and Laparoscopic Surgeon. He is also an endoscopist and bariatric surgeon. He started his surgical practice about 10 years ago. He believes in a patient-centered approach and is known for his compassionate care. "
    }

]


const Home = () => {

    const [ref, inView] = useInView({
        thresholg: 0
    });
    var achievements = <Achievements isStart />
    if (inView) {
        achievements = <Achievements />
    }


    const cardData = [
        {
            title: 'Lorem ipsum',
            logo: Logo1,
            desc: 'ipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have'
        },
        {
            title: 'Lorem ipsum',
            logo: Logo2,
            desc: 'ipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have'
        },
        {
            title: 'Lorem ipsum',
            logo: Logo3,
            desc: 'ipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have'
        }, {
            title: 'Lorem ipsum',
            logo: Logo4,
            desc: 'ipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have'
        }
    ];
    function FormRow(props) {
        return (
            <React.Fragment>
                <Grid container item lg={5} md={12} xs={12}>
                    <Grid item xs={4} s={1} md={3} lg={3}>
                        <Box p={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img src={props.icon1} alt="" />
                        </Box>
                    </Grid>
                    <Grid item xs={8} s={11} lg={7} md={9} direction='column'>
                        <h3 style={{ fontSize: "1.4em", margin: '0', marginTop: '15px', marginBottom: '3px', fontWeight: "bolg" }}>
                            {props.title1}
                        </h3>
                        <span >
                            {props.desc1}
                        </span>
                    </Grid>
                </Grid>
                <Grid container item lg={5} md={12} xs={12}>
                    <Grid item xs={4} lg={3} s={1} md={3}>
                        <Box p={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img src={props.icon2} alt="" />
                        </Box>
                    </Grid>
                    <Grid item xs={8} lg={7} s={11} md={6} direction='column'>
                        <h3 style={{ fontSize: "1.4em", margin: '0', marginTop: '15px', marginBottom: '3px', fontWeight: "bolg" }}>
                            {props.title2}
                        </h3>
                        <span >
                            {props.desc2}
                        </span>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    return (

        <div className="landingPageContainer"
            style={{
                display: 'flex',
                color: '#000',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: '#FFFF',
                padding: '0',

            }}
        >

            <NavBar />
            <div>
                <div style={{ position: 'relative', background: '#0156BD', paddingTop: '1em' }}>

                    <div style={{ position: 'relative', zIndex: '1' }}>
                        <ContentBlock />
                    </div>
                    <div className="custom-shape-divider-bottom-1619165531">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                        </svg>
                    </div>
                </div>
                <div style={{ position: 'relative', background: '#2D89E7' }}>
                    <div className='card-container'>
                        {/* {cardData.map(card => {
                            return <Card cardData={card} />
                        })} */}
                        {/* <Slide bottom>
                            <Box style={{ padding: '10px 20px 10px 20px' }}>
                                <div>
                                    <Grid container justify="center" alignContent="center">
                                        <Grid container justify='center' item xs={12} lg={12} md={12}>
                                            <FormRow
                                                icon1={cardData[0].logo} icon2={cardData[1].logo}
                                                title1={cardData[0].title}
                                                title2={cardData[1].title}
                                                desc1={cardData[0].desc}
                                                desc2={cardData[1].desc} />
                                        </Grid>
                                        <Grid container justify='center' item xs={12} lg={12} md={12}>
                                            <FormRow
                                                icon1={cardData[2].logo} icon2={cardData[3].logo}
                                                title1={cardData[2].title}
                                                title2={cardData[3].title}
                                                desc1={cardData[2].desc}
                                                desc2={cardData[3].desc} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                        </Slide> */}
                    </div>
                    <div className="custom-shape-divider-bottom-1619168723">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                        </svg>
                    </div>

                </div>
                <Grid justifyContent='center' style={{ padding: '1em', background: '#FFF' }} container>
                    {DocData.map(doc => {
                        return (
                            <Grid container justifyContent='center' item lg={4} md={4} xs={12} sm={12}>
                                <DocCard docData={doc} />
                            </Grid>
                        )
                    })

                    }

                    <Grid style={{ gap: 15, border: '10px solid transparent' }} container direction={'row'} item lg={6} md={6} sm={12} xs={12}>
                        <Grid conatiner item lg={12} md={12} sm={12} xs={12} className='commonCard'>
                            <span style={{ fontWeight: '500', fontSize: '20px', marginBottom: '10px' }}>Patient's Data</span>

                            <ResponsiveContainer height={250}>
                                <LineChart data={pdata}  >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" interval={'preserveStartEnd'} tickFormatter={(value, index) => value} />
                                    <YAxis type="number" domain={['auto', 'auto']} />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="linear" dataKey="Patients" stroke="red" activeDot={{ r: 8 }} />

                                </LineChart>
                            </ResponsiveContainer>


                        </Grid>

                    </Grid>


                    <Grid style={{ gap: 15, border: '10px solid transparent' }} container direction={'row'} item lg={6} md={6} sm={12} xs={12}>
                        <Grid conatiner item lg={12} md={12} sm={12} xs={12} className='commonCard'>
                            <span style={{ fontWeight: '500', fontSize: '20px', marginBottom: '10px' }}>Bed's Data</span>

                            <ResponsiveContainer height={300}>
                                <PieChart >
                                    <Tooltip />
                                    <Legend />
                                    <Pie
                                        data={data}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={120}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>

                        </Grid>

                    </Grid>



                </Grid>
                <Box style={{ marginBottom: '2em' }} ref={ref}>
                    {achievements}
                </Box>
                {/* <div className="masterPiece">
                    <span className="heading">Some of our masterpieces!</span>
                    <span className="desc">Unsight Technologies has been creating some amazing works over months and we believe in providing and crafting the best. Checkout out below some of our recent works and get to know our best.</span>
                </div> */}
                {/* MasterPiece component */}

                {/* <Work />
                <Testimonials />
                <h2 style={{ textAlign: 'center', marginTop: '2em' }}>Countries we served</h2>
                <Worlg />
                <Footer /> */}

            </div>

        </div>

    );
};

export default Home;

