import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react'
import './style.css';
import ContactForm from '../../components/ContactForm/index';
import NavBar from '../../components/Navbar';
import ConactImg from '../../assets/contactImg.png';
//import Footer from '../../components/FooterContainer/commonFooter';
/**
* @author
* @function ContactUs
**/

const ContactUs = (props) => {
    return (
        <div style={{
            display: 'flex',
            color: '#000',
            background: 'transparent',
            flexDirection: 'column',
            position: 'relative',
            overflowX: 'hidden',
            backgroundColor: '#FFFF'

        }} className="contactUsContainer">

        <NavBar/>

            <div style={{ zIndex: '12', marginTop: '3.6em', background: '#0156BD' }}>
                <Container alignItems='center' style={{ color: '#ffff', justifyContent: 'center', marginTop: '2em', padding: '1em 0em 8.5em 0em' }}>
                    <Grid container alignItems='center' justify='center' direction='row'>
                        <Grid item lg={6} md={12} >
                            <Box p={2}>
                            <Box p={1} sx={{textAlign:'center'}}>
                                <img style={{width:"100%"}} src={ConactImg} />
                                <Typography variant='h3'>We are here to help you!</Typography>
                            </Box>
                            <Box p={1} sx={{textAlign:'center'}}>
                                <Typography variant='p'>Email: helpdesk@hospital.com</Typography>
                                
                            </Box>
                            <Box p={1} sx={{textAlign:'center'}}>
                                <Typography variant='p'>Telephone: 022 234 567</Typography>
                                
                            </Box>
                            </Box>
                        </Grid>


                        <Grid style={{ marginTop: '1em' }} item lg={6} md={12}>
                            <Box p={1}>
                                <ContactForm />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
                {/* <Footer/> */}
            </div>

        </div>
    )

}

export default ContactUs;
