import './App.css';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import PatientRegister from './pages/Patient/Register';
import PatientDashboard from './pages/Patient/Dashboard';
import Nurses from './pages/Nurse/Register';
import { createTheme, ThemeProvider } from '@mui/material';
import Login from './pages/Login';
import Doctor from './pages/Doctor/Register';
import QRGenerator from './components/QRGenerator';
import Sidebar from './components/Sidebar';
import DoctorDashboard from './pages/Doctor/Dashboard';
// import Patient_report from './pages/Patient_daily_form';
import PatientDetails from './pages/Doctor/PatientDetails';
import Edit_patient_profile from './pages/Patient/Edit_patient_profile';
import Edit_nurse_profile from './pages/Nurse/Edit_nurse_profile';
import Edit_doctor_profile from './pages/Doctor/Edit_Doctor_Profile';
import NurseDashboard from './pages/Nurse/Dashboard';
import NursePatientDetails from './pages/Nurse/PatientDetails';
import Home from './pages/Home';
import Cookies from 'js-cookie';
import React from 'react';
import { useState } from 'react';
import axiosInstance from './utility/axios';
import ShowPatientsForUpload from './pages/UploadReport/showpatients';
import ContactUs from './pages/ContactUs';
import PatientTreatment from './pages/Patient/Treatment';
import AboutUs from './pages/About';
import DoctorList from './pages/DoctorList';
function App() {
  const theme = createTheme({
    typography: {
        fontFamily: [
            'Open sans',

        ].join(','),
    },
});
  // if(Cookies.get('role')==undefined && window.location.pathname!='/login'){
  //   window.location.replace('/login');
  // }
  
  let notShowSidebar=['/','/login','/patient-register','/doctor-register','/nurse-register','/contact-us','/doctor-list','/about'];
  return (
      <ThemeProvider  theme={theme}>
        {/* <QRGenerator title='upi://pay?pa=9763699247@ybl&pn=Priyansh%20Solanki&mc=0000&mode=02&purpose=00'/> */}
        <Router>
        {!notShowSidebar.includes(window.location.pathname)?<Sidebar role={Cookies.get('role')+'-dashboard'}/>:<></>}

          <Routes  >
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
            <Route path="/patient-register" element={<PatientRegister />} />
            <Route path="/doctor-patient-details/:id" element={<PatientDetails />} />
            <Route path="/nurse-patient-details/:id" element={<NursePatientDetails />} />

            <Route path="/login" element={<Login />} />
            <Route path="/doctor-dashboard" element={<DoctorDashboard />} />
            <Route path="/nurse-dashboard" element={<NurseDashboard />} />
            <Route path="/patient-dashboard" element={<PatientDashboard />} />
            <Route path="/patient-treatment" element={<PatientTreatment />} />
            <Route path="/nurse-register" element={<Nurses />} />
            <Route path="/doctor-register" element={<Doctor />} />
            {/* <Route path="/Patient-report" element={<Patient_report />} /> */}
            <Route path="/patient-profile" element={<Edit_patient_profile />} />
            <Route path="/nurse-profile" element={<Edit_nurse_profile />} />
            <Route path="/doctor-profile" element={<Edit_doctor_profile />} />
            <Route path="/upload-reports" element={<ShowPatientsForUpload/>}/>
            <Route path="/doctor-list" element={<DoctorList/>}/>


          </Routes>
        </Router>
      </ThemeProvider>
  );
}

export default App;