import { Box, Button, Fade, Grid, Modal, Backdrop } from "@mui/material";
import React from "react";
import { Phone } from '@mui/icons-material';
import Dummy from './dummy.png';
import QRGenerator from "../QRGenerator";
import axiosInstance from "../../utility/axios";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '60%',

    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const PatientDetailsCard = props => {

    const [qrLink, setQRLink] = React.useState('naitik');
    const [open, setOpen] = React.useState(false);
    const baseUrl='https://arogya-doot.pages.dev/nurse-patient-details/';
    return (
        <Grid style={{ position: 'relative', border: '10px solid #E1E1E1' }}>
            <Grid style={{ position: 'absolute', right: '10px', top: '10px' }} container justifyContent={'space-between'}>
                <h2></h2>
                <Button style={{ height: '30px' }} onClick={() => {
                    setQRLink(baseUrl+props.patient.id);
                    setOpen(true);
                }} size='small' variant='contained'>QR Code</Button>
            </Grid>
            <Grid style={{ position: 'absolute', right: '10px', bottom: '10px' }} container justifyContent={'space-between'}>
                <h2></h2>
                <Button style={{ height: '30px',background:'red' }} onClick={() => {
                   axiosInstance.get(`/patient/SOS/?patient_id=${props.patient.id}`).then(res => {
                       alert("Email has been sent to the doctor for emergncy contact");
                   });
                }}  variant='contained'>&nbsp;SOS&nbsp;</Button>
            </Grid>

            <Grid className='commonCard' container justifyContent={'space-between'} >
                <Grid container justifyContent={'center'} item lg={2} md={12} sm={12} xs={12}>
                    <img style={{ width: '150px', borderRadius: '50%' }} src={Dummy} />
                </Grid>
                <Grid container item lg={9} md={9} sm={12} xs={12} justifyContent={'space-between'}>
                    <Grid item lg={5} md={6} sm={12} xs={12}>
                        <span style={{ fontWeight: '500', fontSize: '25px', padding: '0.5em 0' }}>{props.patient.full_name}</span>

                        <Grid container style={{ padding: '0.5em 0' }} justifyContent='space-between' >
                            <span style={{ fontWeight: '500', fontSize: 'large' }}>Gender : {props.patient.gender}</span>
                            <span style={{ fontWeight: '500', fontSize: 'large' }}>Age : {props.patient.age} &nbsp;&nbsp;&nbsp;<span style={{ color: 'red', fontWeight: '600' }}> {props.patient.bloodgroup}</span></span>

                        </Grid>
                        <Grid container style={{ padding: '0.5em 0' }} justifyContent='space-between'>
                            <span style={{ fontWeight: '500', fontSize: 'large' }}>Room no : {props.patient.allocated_bed}</span><br />
                            <span style={{ fontWeight: '500', fontSize: 'large' }}>Bed no : {props.patient.allocated_bed} &nbsp;&nbsp;&nbsp;</span>

                        </Grid>
                        <Grid container alignItems='center'>
                            <Phone /><span style={{ fontWeight: '500', fontSize: 'large', padding: '0.5em 0' }}>&nbsp; {props.patient.phone}</span><br />
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* <span style={{ fontWeight: '500', fontSize: 'large', color: 'green' }}>Emergency Details :</span><br /> */}
                        <span style={{ fontWeight: '500', fontSize: '25px', padding: '0.5em 0', color: 'green' }}>Emergency Details</span><br />
                        <Grid container style={{ padding: '0.5em 0' }} >

                            <span style={{ fontWeight: '500', fontSize: 'large' }}>Name : {props.patient.emergency_contact_name ? props.patient.emergency_contact_name : ''}</span><br />
                            <Grid container style={{ padding: '0.9em 0' }} alignItems='center'>
                                <br /> <Phone /><span style={{ fontWeight: '500', fontSize: 'large' }}>&nbsp; {props.patient.emergency_contact_phone ? props.patient.emergency_contact_phone : ''}</span><br />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => { setOpen(false) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade style={{width:'90%'}} in={open}>
                    <Box sx={style}>
                        <Grid container justifyContent={'center'} alignItems='center'>
                            <QRGenerator title={qrLink} />

                        </Grid>
                    </Box>
                </Fade>
            </Modal>

        </Grid>
    );
}
export default PatientDetailsCard;