import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../utility/dimensions';
import { FormHelperText } from '@mui/material';
import '../../../commonStyle.css';
import Dummy from '../../../assets/logo5.svg';
import PatientDetailsCard from '../../../components/PatientDetailsCard';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import themeMain from '../../../theme';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, BarChart, Bar } from 'recharts';
import axiosInstance from '../../../utility/axios';
import DocInfoCard from '../../../components/DocInfoCard';
import CancelIcon from '@mui/icons-material/Cancel';

//charts data
const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase()
    return array.filter(value => {
        return value.nurse_name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.treatment.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.treatment_time.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.details.toLowerCase().match(new RegExp(searchTerm, 'g'))

    })
}


const pdata = [
    {
        name: 'Day1',
        temperature: 101,

    },
    {
        name: 'Day2',
        temperature: 97,

    },
    {
        name: 'Day3',
        temperature: 103,

    },
    {
        name: 'Day4',
        temperature: 99,

    },
    {
        name: 'Day5',
        temperature: 97,

    },
    {
        name: 'Day6',
        temperature: 98,

    },
];

//Treatment Modal
const styleT = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    width: '60%',
    color: '#fff',
    bgcolor: '#012764',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

//Obervation Modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '60%',

    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

//Treatment table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: '600'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: '600'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));





const rows = [

    { treatment: "Ringer's lactate solution", time: "10am-11am", addInfo: "Give it after breakfast", nurseStatus: true },
    { treatment: "Zatherine", time: "7pm-8pm", addInfo: "Give it after dinner", nurseStatus: true },
    { treatment: "Injection", time: "11am-1pm", addInfo: "Give it after lunch", nurseStatus: true }


];


const PatientTreatment = (props) => {

    //Treatment form
    //Form data
    const [treatmentData, settreatmentData] = useState({

        treat: '',
        time: '',
        addinfo: ''


    })

    const [editTreatmentData, seteditTreatmentData] = useState({

        treatment: '',
        time: '',
        addInfo: ''


    })

    const [loader, setLoader] = useState(true);
    const [patientInfo, setPatientInfo] = useState();
    const [doctorInfo, setDoctorInfo] = useState();
    const [nurseList, setNurseList] = useState();
    const [observationList, setObservationList] = useState();
    const [treatmentList,setTreatmentList]=useState();
    React.useEffect(() => {
        axiosInstance.get(`/auth/get_details/`).then(res => {
            console.log(res.data);
            setPatientInfo(res.data.details);
            axiosInstance.get(`/doctor/doctor_info/${res.data.details.id}/`).then(r => {
                setDoctorInfo(r.data);
                setLoader(false);


            }).catch(err => {
                setLoader(false);
            });
            axiosInstance.get(`/nurse/operated_nurse_list/?patient_id=${res.data.details.id}`).then(re => {
                setNurseList(re.data);
                setLoader(false);


            }).catch(err => {
                setLoader(false);
            });
            axiosInstance.get(`/patient/observations/`).then(re => {
                setObservationList(re.data.reverse());
                setLoader(false);


            }).catch(err => {
                setLoader(false);
            });
            axiosInstance.get(`/patient/treatments/`).then(reso => {
                reso.data.map(o => {
                    if (o.completed_at == null)
                        o.completed_at = '';
                    if (o.created_at == null)
                        o.created_at = '';
                    if (o.details == null)
                        o.details = '';
                    if (o.nurse_name == null)
                        o.nurse_name = '';
                    if (o.treatment == null)
                        o.treatment = '';
                    if (o.treatment_time == null)
                        o.treatment_time = '';
                });
                let tmp = reso.data.reverse();
                setTreatmentList(tmp);
                setStockData1(tmp);
                setLoader(false);


            }).catch(err => {
                setLoader(false);
            });
        }).catch(err => {
            setLoader(false);
        })
    }, []);
    const [stockData1, setStockData1] = useState([]);
    const [count, setCount] = useState(0);

    const handleChange = async (e) => {
        let value = e.target.value;
        console.log(e.target.value);
        if (treatmentList) {
            if (value.length >= 2) {
                let search = await arraySearch(treatmentList, value);
                setTreatmentList(search)
                console.log(search);
                setCount(search.length)

            } else {
                setTreatmentList(stockData1)
                setCount(stockData1.length)
            }
        }
    }
    // Observation Modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Treatment Modal
    const [openT, setOpenT] = React.useState(false);
    const handleOpenT = () => setOpenT(true);
    const handleCloseT = () => setOpenT(false);

    // Edit Treatment Modal
    const [openTE, setOpenTE] = React.useState(false);
    const handleOpenTE = () => setOpenTE(true);
    const handleCloseTE = () => setOpenTE(false);

    const [treatError, settreatError] = React.useState(false);
    const [treatErrorText, settreatErrorText] = React.useState("");

    const [timeError, settimeError] = React.useState(false);
    const [timeErrorText, settimeErrorText] = React.useState("");

    const [addinfoError, setaddinfoError] = React.useState(false);
    const [addinfoErrorText, setaddinfoErrorText] = React.useState("");
    const [currentObservation,setCurrentObservation]=useState(-1);
    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const { height, width } = useWindowDimensions();
    const [date, setDate] = React.useState(new Date());
    const patient = {
        name: 'Priyansh Sunilkumar Solanki',
        age: '22',
        bloodgroup: 'B+',
        contact: '976543210',
        gender: 'Male',
        ename: 'Jayanth Pabothu',
        econtact: '987654321',
        roomno: '341',
        bedno: '21'
    }


    const validateData = () => {
        settreatError(ValidateSingleField(treatmentData.treat));
        ValidateSingleField(treatmentData.treat) ? settreatErrorText('Medication cannot be blank') : settreatErrorText('');

        settimeError(ValidateSingleField(treatmentData.time));
        ValidateSingleField(treatmentData.time) ? settimeErrorText('Medication time cannot be blank') : settimeErrorText('');

        // setaddinfoError(ValidateSingleField(treatmentData.addinfo));
        // ValidateSingleField(treatmentData.addinfo) ? setaddinfoErrorText('cannot be blank') : setaddinfoErrorText('');




        if (
            !ValidateSingleField(treatmentData.treat) &&
            !ValidateSingleField(treatmentData.time)


        ) {


            return true;


        }
        return false;


    }
    return (
        loader && patientInfo == undefined ?
            <div style={{ display: 'flex', height: '100vh', justifyContent: 'center' }} >
                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
            </div>
            :
            <>
                <Grid className='dashboard' container alignItems='flex-start' style={{ height: 'auto', paddingTop: '1em', paddingBottom: '2%' }} justifyContent='center'>


                    <Grid item style={{ marginLeft: width > 1079 ? width > 1250 ? '27%' : '38%' : '0px' }} lg={8} md={11} sm={11} xs={11}>
                        {width <= 600 ?
                            <><br /><br /></> : <></>}


                     

                        <br />
                        {/* <PatientDetailsCard patient={patientInfo} /> */}

                        <Grid container >
                            {/* <Grid style={{ border: '10px solid transparent' }} container item lg={6} md={6} sm={12} xs={12}>
                                {doctorInfo != undefined ? <DocInfoCard doctor={doctorInfo} /> :
                                    <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                                        <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                    </div >}
                            </Grid>

                            <Grid style={{ border: '10px solid transparent' }} container item lg={6} md={6} sm={12} xs={12}>
                                <Grid container className='commonCard' >

                                    <span style={{ fontWeight: '500', fontSize: '25px' }}>Nurses operated</span>

                                    <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5, height: '15em', flexWrap: 'nowrap', overflowY: 'scroll', marginTop: '10px' }} className='commonCard' direction={'column'} container alignItems={'flex-start'} justifyContent={'flex-start'}>
                                        {
                                            nurseList != undefined ? nurseList.map((nurse, index) => <span style={{ fontWeight: '500', fontSize: 'X-large', color: '#000' }}>{index + 1}. {nurse.full_name}</span>) : <></>
                                        }
                                    </Grid>

                                </Grid>
                            </Grid>
 */}
                            {/* <Grid style={{ gap: 15, border: '10px solid transparent' }} container direction={'row'} item lg={12} md={6} sm={12} xs={12}>
                                <Grid conatiner item lg={12} md={12} sm={12} xs={12} className='commonCard'>
                                    <Grid container alignItems={'flex-start'} justifyContent={'space-between'}>
                                        <span style={{ fontWeight: '500', fontSize: '25px' }}>Observations</span>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker

                                                renderInput={(props) => <TextField size={'small'} {...props} />}
                                                label="DateTimePicker"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid style={{ flexWrap: 'nowrap', height: '16em', padding: '0.5em', overflowX: 'hidden', overflowY: 'scroll', gap: 15, marginTop: '1em' }} container direction={'column'}>
                                        {
                                            observationList == undefined ?
                                                <div style={{ display: 'flex', minHeight: '10vh', alignItems: 'center', justifyContent: 'center' }} >
                                                    <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                                </div >
                                                :
                                                observationList.length == 0 ? <h3>No Observations yet</h3> :
                                                    observationList.map(observation => {
                                                        return (
                                                            <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5 }} className='commonCard' container alignItems={'flex-start'} justifyContent={'flex-start'}>
                                                                <button className='commonButton' size='small' onClick={() => {
                                                                    handleOpen();
                                                                    setCurrentObservation(observation);
                                                                }}  >View Observations</button>
                                                                <span style={{ fontWeight: '500', fontSize: 'small', color: 'rgb(126 126 126)' }}>{(observation.created_at.replace('T', ' ')).slice(0, -8)}</span>
                                                            </Grid>
                                                        );
                                                    })
                                        }


                                    </Grid>
                                </Grid>

                            </Grid> */}

                            <Grid style={{ border: '10px solid transparent' }} container item lg={12} md={12} sm={12} xs={12}>
                                <Grid container className='commonCard' >


                                    <br />
                                    <Grid container justifyContent={'space-between'} direction="row" spacing={1}>
                                        <Grid item lg={6} md={6}>
                                            <span style={{ fontWeight: '500', fontSize: '25px' }}>Treatment Details</span>
                                        </Grid>
                                        <Grid item lg={6} md={6} >
                                            <TextField
                                                className='inputField'
                                                placeholder='Search'
                                                variant='outlined'
                                                type='text'


                                                onChange={handleChange}

                                            />
                                        </Grid>
                                       

                                    </Grid>
                                    <TableContainer component={Paper} style={{ marginTop: '1em' }}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">Sr.</StyledTableCell>

                                                <StyledTableCell>Treatment/Medication</StyledTableCell>
                                                <StyledTableCell align="center">Time</StyledTableCell>
                                                <StyledTableCell align="center">Additional Info</StyledTableCell>
                                                <StyledTableCell align="center">Status</StyledTableCell>
                                                <StyledTableCell align="center">By Nurse</StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                        {treatmentList == undefined ?
                                            <div style={{ display: 'flex', minHeight: '10vh', alignItems: 'center', justifyContent: 'center' }} >
                                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                            </div > :
                                            treatmentList.length == 0 ? <h3>No Treatment</h3> :
                                                <TableBody>
                                                    {treatmentList.map((row, idx) => (
                                                        <StyledTableRow key={row.idx}>
                                                            <StyledTableCell align="center">{idx + 1}</StyledTableCell>

                                                            <StyledTableCell >
                                                                {row.treatment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{row.treatment_time.replace('T', ' ').slice(0, -1)}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.details}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.status ? <CheckCircleOutlineIcon color={"success"} /> : <CancelIcon color="error" />}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.nurse_name}</StyledTableCell>

                                                           
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>

                                        }
                                    </Table>
                                </TableContainer>




                                </Grid>




                            </Grid>
                        </Grid>

                        {/* <Grid style={{ gap: 15, border: '10px solid transparent' }} container direction={'row'} item lg={6} md={6} sm={12} xs={12}>
                            <Grid conatiner item lg={12} md={12} sm={12} xs={12} className='commonCard'>
                                <span style={{ fontWeight: '500', fontSize: '20px', marginBottom: '10px' }}>Obervation Chart</span>

                                <ResponsiveContainer aspect={2}>
                                    <LineChart data={pdata}  >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" interval={'preserveStartEnd'} tickFormatter={(value, index) => "Day" + (index + 1)} />
                                        <YAxis type="number" domain={['auto', 'auto']} />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="linear" dataKey="temperature" stroke="red" activeDot={{ r: 8 }} />

                                    </LineChart>
                                </ResponsiveContainer>


                            </Grid>

                        </Grid> */}





                    </Grid>

                </Grid>

               {/* Observation modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div style={{ margin: '0.5em' }}>



                            <br />
                            <Grid container spacing={3} >

                                <Grid lg={12} sm={12} xs={12} >

                                    <Typography style={{ fontWeight: '700', fontSize: '20px', marginBottom: '1em' }}>Nurse: {currentObservation.nurse_name}</Typography>


                                </Grid>

                                <Grid lg={3} sm={12} xs={12} >

                                    <Typography style={{ fontWeight: '600' }}>Temp</Typography>
                                    <Typography>{currentObservation.temperature}</Typography>


                                </Grid>
                                <Grid lg={3} sm={12} xs={12} >
                                    <Typography style={{ fontWeight: '600' }}>B.P</Typography>
                                    <Typography>{currentObservation.blood_pressure}/90</Typography>
                                </Grid>
                                <Grid lg={3} sm={12} xs={12}>
                                    <Typography style={{ fontWeight: '600' }}>O.L</Typography>
                                    <Typography>{currentObservation.oxygen_level}</Typography>
                                </Grid>
                                <Grid lg={3} sm={12} xs={12} >
                                    <Typography style={{ fontWeight: '600' }}>H.R</Typography>
                                    <Typography>{currentObservation.heart_rate} bpm</Typography>
                                </Grid>
                                <Grid lg={12} sm={12} xs={12} >
                                    <Typography style={{ fontWeight: '600', marginTop: '0.5em' }}>Comment</Typography>
                                    <div style={{ borderStyle: 'solid', borderColor: '#000', height: '10em' }}>
                                        <Typography variant='body1'>{currentObservation.comment}</Typography>
                                    </div>

                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </Fade>
            </Modal>





            </>



    );
}
export default PatientTreatment;