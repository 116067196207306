import React from 'react'
import './style.css';
import Slide from "react-reveal/Slide";
import MainImage from '../../../assets/Landing_Img02.png';
/**
* @author
* @function ContentRight
**/

const ContentRight = (props) => {
  return(
    <div className="contentRightContainer">
      <Slide right>
        <img style={{width:'100%'}} src={MainImage}/>
      </Slide>

    </div>
   )

 }

export default ContentRight;