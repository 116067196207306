import { ThemeProvider } from "@mui/material";
import { Checkbox, TextField, Button, CircularProgress, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './style.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import pic from "../../../assets/background.jpg";
import MuiPhoneNumber from 'material-ui-phone-number';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import themeMain from '../../../theme';
import FormControl from '@mui/material/FormControl';
import { createTheme } from '@material-ui/core/styles';
import { useWindowDimensions } from '../../../utility/dimensions';
import { PictureAsPdfSharp } from "@mui/icons-material";
import axiosInstance from "../../../utility/axios";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const NurseRegister = (props) => {

    const { width } = useWindowDimensions();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    //Form data
    const [nurseData, setnurseData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        dob: '',
        contact: '',
        address: '',
        adharr: '',
        bloodGroup: "",
        age: '',
        gender: ''


    })
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Open Sans',

            ].join(','),
        },
    });

    //states

    const [firstNameError, setFirstNameError] = React.useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = React.useState("");

    const [lastNameError, setLastNameError] = React.useState(false);
    const [lastNameErrorText, setLastNameErrorText] = React.useState("");

    const [emailError, setemailError] = React.useState(false);
    const [emailErrorText, setemailErrorText] = React.useState("");

    const [addressError, setaddressError] = React.useState(false);
    const [addressErrorText, setaddressErrorText] = React.useState("");

    const [dobError, setdobError] = React.useState(false);
    const [dobErrorText, setdobErrorText] = React.useState("");

    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorText, setPasswordErrorText] = React.useState("");

    const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = React.useState("");

    const [contactError, setContactError] = React.useState(false);
    const [contactErrorText, setContactErrorText] = React.useState("");

    const [adharrError, setAdharrError] = React.useState(false);
    const [adharrErrorText, setAdharrErrorText] = React.useState("");

    const [bloodGroupError, setBloodGroupError] = React.useState(false);
    const [bloodGroupErrorText, setBloodGroupErrorText] = React.useState("");

    const [ageError, setAgeError] = React.useState(false);
    const [ageErrorText, setAgeErrorText] = React.useState("");

    const [genderError, setGenderError] = React.useState(false);
    const [genderErrorText, setGenderErrorText] = React.useState("");





    //password visibility
    const [values1, setValues1] = React.useState({
        password: '',
        showPassword: false,
    });
    const [values2, setValues2] = React.useState({
        password: '',
        showPassword: false,
    });

    //Handle Password
    const handleChange1 = (prop) => (event) => {
        setPasswordError(false);
        setPasswordErrorText('');
        setValues1({ ...values1, [prop]: event.target.value });
        setnurseData({ ...nurseData, [prop]: event.target.value });
    };

    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    };

    const handleChange2 = (prop) => (event) => {
        setConfirmPasswordError(false);
        setConfirmPasswordErrorText('');
        setValues2({ ...values2, [prop]: event.target.value });
    };
    const handleClickShowPassword2 = () => {
        setValues2({ ...values2, showPassword: !values2.showPassword });
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validateData = () => {
        setFirstNameError(ValidateSingleField(nurseData.firstName));
        ValidateSingleField(nurseData.firstName) ? setFirstNameErrorText('First Name cannot be blank') : setFirstNameErrorText('');


        setLastNameError(ValidateSingleField(nurseData.lastName));
        ValidateSingleField(nurseData.lastName) ? setLastNameErrorText('Last Name cannot be blank') : setLastNameErrorText('');


        setemailError(ValidateSingleField(nurseData.email));
        ValidateSingleField(nurseData.email) ? setemailErrorText('Email cannot be blank') : setemailErrorText('');
        if (nurseData.email) {
            setemailError(!validateEmail(nurseData.email));
            setemailErrorText(!validateEmail(nurseData.email) ? 'Please enter correct email' : '');
        }

        setContactError(ValidateSingleField(nurseData.contact));
        ValidateSingleField(nurseData.contact) ? setContactErrorText('Contact cannot be blank') : setContactErrorText('');

        setPasswordError(ValidateSingleField(values1.password));
        ValidateSingleField(values1.password) ? setPasswordErrorText('Password cannot be blank') : setPasswordErrorText('');

        setConfirmPasswordError(ValidateSingleField(values2.password));
        ValidateSingleField(values2.password) ? setConfirmPasswordErrorText('Confirm Password cannot be blank') : setConfirmPasswordErrorText('');

        if (values1.password !== values2.password) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorText('Password doesn\'t match');
        }

        if (nurseData.dob.length > 10) {
            setdobError(true);
            setdobErrorText('Please enter correct date');
        }

        setdobError(ValidateSingleField(nurseData.dob));
        ValidateSingleField(nurseData.dob) ? setdobErrorText('dob cannot be blank') : setdobErrorText('');

        setaddressError(ValidateSingleField(nurseData.address));
        ValidateSingleField(nurseData.address) ? setaddressErrorText('Address cannot be blank') : setaddressErrorText('');

        setAdharrError(ValidateSingleField(nurseData.adharr));
        ValidateSingleField(nurseData.adharr) ? setAdharrErrorText('Adharr cannot be blank') : setAdharrErrorText('');

        setBloodGroupError(ValidateSingleField(nurseData.bloodGroup));
        ValidateSingleField(nurseData.bloodGroup) ? setBloodGroupErrorText('Blood group cannot be blank') : setBloodGroupErrorText('');

        setAgeError(ValidateSingleField(nurseData.age));
        ValidateSingleField(nurseData.age) ? setAgeErrorText('Age cannot be blank') : setAgeErrorText('');

        setGenderError(ValidateSingleField(nurseData.gender));
        ValidateSingleField(nurseData.gender) ? setGenderErrorText('Gender cannot be blank') : setGenderErrorText('');






        if ((values1.password == values2.password) &&
            validateEmail(nurseData.email) &&
            !ValidateSingleField(nurseData.firstName) &&
            !ValidateSingleField(nurseData.lastName) &&
            !ValidateSingleField(nurseData.email) &&
            !ValidateSingleField(values1.password) &&
            !ValidateSingleField(values2.password) &&
            !ValidateSingleField(nurseData.contact) &&
            !ValidateSingleField(nurseData.dob) &&
            !ValidateSingleField(nurseData.address) &&
            !ValidateSingleField(nurseData.adharr) &&
            !ValidateSingleField(nurseData.bloodGroup) &&
            !ValidateSingleField(nurseData.age) &&
            !ValidateSingleField(nurseData.gender) &&


            nurseData.dob.length <= 10

        ) {


            return true;


        }
        return false;


    }

    return (


        <ThemeProvider theme={theme}>
            <div style={{ position: 'relative', zIndex: '1' }}>
                <Grid container style={{ minHeight: '100vh' }}>

                    <Grid style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'auto', backgroundImage: `url(${pic})` }} item lg={12} md={12} sm={12} xs={12}>
                        <div style={{
                            width: '100%',
                            maxWidth: '45em'
                        }}>

                            <Grid style={{ padding: width > 430 ? '3em 2.3em' : '3em 1em', minHeight: '100vh', color: '#fff' }} container direction='column' >
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center' }}>
                                    <Typography className='text' variant='h4' >Nurse Registration</Typography>
                                    <br />

                                </div>


                                <br />
                                <form onSubmit={e => e.preventDefault()}>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid spacing={2} container item lg={12} md={12} sm={12} xs={12} direction='row'>

                                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                <span className='label'>First name</span>
                                                <TextField className='inputField' type="text"
                                                    placeholder='First name' variant='outlined'
                                                    error={firstNameError}
                                                    value={nurseData.firstName}
                                                    onChange={(event) => {
                                                        setFirstNameError(false);
                                                        setFirstNameErrorText('');
                                                        setnurseData({ ...nurseData, firstName: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{firstNameErrorText}</FormHelperText>
                                            </Grid>
                                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                <span className='label'>Last name</span>
                                                <TextField className='inputField' type="text"
                                                    placeholder='Last name' variant='outlined'
                                                    error={lastNameError}
                                                    value={nurseData.lastName}
                                                    onChange={(event) => {
                                                        setLastNameError(false);
                                                        setLastNameErrorText('');
                                                        setnurseData({ ...nurseData, lastName: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{lastNameErrorText}</FormHelperText>
                                            </Grid>



                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Email address</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='name@example.com'
                                                    variant='outlined'
                                                    type='email'

                                                    error={emailError}
                                                    value={nurseData.email}

                                                    onChange={(event) => {
                                                        setemailError(false);
                                                        setemailErrorText('');
                                                        setnurseData({ ...nurseData, email: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{emailErrorText}</FormHelperText>

                                            </Grid>
                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Password</span>
                                                <OutlinedInput
                                                    className='inputField'
                                                    placeholder='6 characters, 1 capital letter'
                                                    variant='outlined'
                                                    error={passwordError}
                                                    type={values1.showPassword ? 'text' : 'password'}
                                                    value={values1.password}
                                                    onChange={handleChange1('password')}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton tabIndex='-1'
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword1}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {values1.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText style={{ color: 'red' }}>{passwordErrorText}</FormHelperText>
                                            </Grid>
                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Confirm Password</span>
                                                <OutlinedInput
                                                    className='inputField'
                                                    placeholder='6 characters, 1 capital letter'
                                                    variant='outlined'
                                                    error={confirmPasswordError}
                                                    type={values2.showPassword ? 'text' : 'password'}
                                                    value={values2.password}
                                                    onChange={handleChange2('password')}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton tabIndex='-1'
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword2}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {values2.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText style={{ color: 'red' }}>{confirmPasswordErrorText}</FormHelperText>
                                            </Grid>
                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Date of Birth</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='dd/mm/yyyy'
                                                    variant='outlined'
                                                    type='date'
                                                    error={dobError}
                                                    value={nurseData.dob}
                                                    onChange={(event) => {

                                                        setdobError(false);
                                                        setdobErrorText('');
                                                        if (event.target.value.length > 10) {
                                                            setdobError(true);
                                                            setdobErrorText('Please enter correct date');
                                                        }
                                                        setnurseData({ ...nurseData, dob: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{dobErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Address</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='Flatno:, Landmark, City - Pincode'
                                                    variant='outlined'
                                                    multiline
                                                    maxRows={3}
                                                    value={nurseData.address}
                                                    onChange={(event) => {
                                                        setaddressError(false);
                                                        setaddressErrorText('');
                                                        setnurseData({ ...nurseData, address: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{addressErrorText}</FormHelperText>

                                            </Grid>
                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Contact</span>
                                                <MuiPhoneNumber
                                                    defaultCountry={'in'}
                                                    className='inputField'
                                                    placeholder='9876543210'
                                                    variant='outlined'
                                                    error={contactError}
                                                    value={nurseData.contact}
                                                    onChange={(event) => {
                                                        setContactError(false);
                                                        setContactErrorText('');
                                                        setnurseData({ ...nurseData, contact: event })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{contactErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Identification Number</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='1129NXEP4K'
                                                    variant='outlined'
                                                    error={adharrError}
                                                    value={nurseData.adharr}
                                                    onChange={(event) => {
                                                        setAdharrError(false);
                                                        setAdharrErrorText('');
                                                        setnurseData({ ...nurseData, adharr: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{adharrErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Blood Group</span>
                                                <FormControl error={bloodGroupError}>
                                                    <Select
                                                        className='inputField'
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={nurseData.bloodGroup}
                                                        onChange={(event) => {
                                                            setBloodGroupError(false);
                                                            setBloodGroupErrorText('');
                                                            setnurseData({ ...nurseData, bloodGroup: event.target.value })
                                                        }}
                                                    >

                                                        <MenuItem value={"A+"}>A+</MenuItem>
                                                        <MenuItem value={"A-"}>A-</MenuItem>
                                                        <MenuItem value={"B+"}>B+</MenuItem>
                                                        <MenuItem value={"B-"}>B-</MenuItem>
                                                        <MenuItem value={"AB+"}>AB+</MenuItem>
                                                        <MenuItem value={"AB-"}>AB-</MenuItem>
                                                        <MenuItem value={"O+"}>O+</MenuItem>
                                                        <MenuItem value={"O-"}>O-</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormHelperText style={{ color: 'red' }}>{bloodGroupErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Age</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='34'
                                                    variant='outlined'
                                                    type='text'
                                                    error={ageError}
                                                    value={nurseData.age}

                                                    onChange={(event) => {
                                                        setAgeError(false);
                                                        setAgeErrorText('');
                                                        setnurseData({ ...nurseData, age: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{ageErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Gender</span>
                                                <FormControl error={genderError}>
                                                    <Select
                                                        className='inputField'
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={nurseData.gender}
                                                        onChange={(event) => {
                                                            setGenderError(false);
                                                            setGenderErrorText('');
                                                            setnurseData({ ...nurseData, gender: event.target.value })
                                                        }}
                                                    >

                                                        <MenuItem value={"Male"}>Male</MenuItem>
                                                        <MenuItem value={"Female"}>Female</MenuItem>
                                                        <MenuItem value={"Other"}>Other</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormHelperText style={{ color: 'red' }}>{genderErrorText}</FormHelperText>

                                            </Grid>












                                            <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                {loader ? <div style={{ display: 'flex', justifyContent: 'center' }} >
                                                    <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                                </div> : <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ fontSize: '20px', color: themeMain.palette.primary.main, background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 60px' }} className='demoSubmitButton'
                                                    variant="contained"
                                                    onClick={() => {
                                                        console.log(nurseData);
                                                        if (validateData()) {
                                                            const tmpData = {
                                                                username: nurseData.email,
                                                                email: nurseData.email,
                                                                first_name: nurseData.firstName,
                                                                last_name: nurseData.lastName,
                                                                password: nurseData.password,
                                                                password2: nurseData.password,
                                                                phone: nurseData.contact,
                                                                dob: nurseData.dob,
                                                                aadhaar: nurseData.adharr,
                                                                address: nurseData.address,
                                                                blood_group: nurseData.bloodGroup,
                                                                age: nurseData.age,
                                                                gender: nurseData.gender
                                                            }
                                                            setLoader(true);
                                                            axiosInstance.post('/nurse/register/', tmpData).then((res) => {
                                                                setLoader(false);
                                                                setIsError(false);
                                                                setSnackbarMessage("Registration Successful");
                                                                setOpen(true);
                                                                navigate('/login');
                                                                // console.log(res.data);

                                                            }).catch((err) => {
                                                                console.log(err.response.data.error);
                                                                setLoader(false);

                                                                setIsError(true);
                                                                let tmp = '';
                                                                if (err.response.data.error.password) {
                                                                    err.response.data.error.password.map(e => {
                                                                        tmp += e.toString() + '\n';
                                                                    })
                                                                }
                                                                if (err.response.data.error.username || err.response.data.error.email) {
                                                                    tmp += err.response.data.error.username[0];
                                                                }

                                                                setSnackbarMessage(tmp);
                                                                setOpen(true);


                                                            });

                                                        }
                                                    }
                                                    }

                                                >Submit</Button></div>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>

                            </Grid>
                        </div>
                        <Snackbar open={open} autoHideDuration={2000} onClose={() => {
                            setOpen(false);
                        }}>
                            <Alert onClose={() => {
                                setOpen(false);
                            }} severity={isError ? "error" : "success"} sx={{ width: '100%' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Grid>

                </Grid>
            </div>

        </ThemeProvider>
    );
}


export default NurseRegister;