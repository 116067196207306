import { ThemeProvider } from "@mui/material";
import { Checkbox, TextField, Button, CircularProgress, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './style.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MuiPhoneNumber from 'material-ui-phone-number';
import FormControl from '@mui/material/FormControl';
import themeMain from '../../../theme';
import { createTheme } from '@material-ui/core/styles';
import { useWindowDimensions } from '../../../utility/dimensions';
import pic from '../../../assets/background.jpg'
import axiosInstance from "../../../utility/axios";
import { useNavigate } from "react-router-dom";
const PatientRegister = (props) => {

    const { width } = useWindowDimensions();


    //Form data
    const [patientData, setPatientData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        dob: '',
        contact: '',
        address: '',
        adharr: '',
        bloodGroup: "",
        age: '',
        gender: '',
        pFirstName: '',
        pLastName: '',
        pRelation: '',
        pContact: '',
        doctor: '',
        bedType: ''


    })
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Open Sans',

            ].join(','),
        },
    });

    //states

    const [firstNameError, setFirstNameError] = React.useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = React.useState("");

    const [lastNameError, setLastNameError] = React.useState(false);
    const [lastNameErrorText, setLastNameErrorText] = React.useState("");

    const [emailError, setemailError] = React.useState(false);
    const [emailErrorText, setemailErrorText] = React.useState("");

    const [addressError, setaddressError] = React.useState(false);
    const [addressErrorText, setaddressErrorText] = React.useState("");

    const [dobError, setDobError] = React.useState(false);
    const [dobErrorText, setDobErrorText] = React.useState("");

    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorText, setPasswordErrorText] = React.useState("");

    const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = React.useState("");

    const [contactError, setContactError] = React.useState(false);
    const [contactErrorText, setContactErrorText] = React.useState("");

    const [adharrError, setAdharrError] = React.useState(false);
    const [adharrErrorText, setAdharrErrorText] = React.useState("");

    const [bloodGroupError, setBloodGroupError] = React.useState(false);
    const [bloodGroupErrorText, setBloodGroupErrorText] = React.useState("");

    const [ageError, setAgeError] = React.useState(false);
    const [ageErrorText, setAgeErrorText] = React.useState("");

    const [genderError, setGenderError] = React.useState(false);
    const [genderErrorText, setGenderErrorText] = React.useState("");

    const [pFirstNameError, setpFirstNameError] = React.useState(false);
    const [pFirstNameErrorText, setpFirstNameErrorText] = React.useState("");

    const [pLastNameError, setpLastNameError] = React.useState(false);
    const [pLastNameErrorText, setpLastNameErrorText] = React.useState("");

    const [pRelationError, setpRelationError] = React.useState(false);
    const [pRelationErrorText, setpRelationErrorText] = React.useState("");

    const [pContactError, setpContactError] = React.useState(false);
    const [pContactErrorText, setpContactErrorText] = React.useState("");



    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }


    const validateData = () => {
        setFirstNameError(ValidateSingleField(patientData.firstName));
        ValidateSingleField(patientData.firstName) ? setFirstNameErrorText('First Name cannot be blank') : setFirstNameErrorText('');


        setLastNameError(ValidateSingleField(patientData.lastName));
        ValidateSingleField(patientData.lastName) ? setLastNameErrorText('Last Name cannot be blank') : setLastNameErrorText('');



        setContactError(ValidateSingleField(patientData.contact));
        ValidateSingleField(patientData.contact) ? setContactErrorText('Contact cannot be blank') : setContactErrorText('');


        if (patientData.dob.length > 10) {
            setDobError(true);
            setDobErrorText('Please enter correct date');
        }

        setDobError(ValidateSingleField(patientData.dob));
        ValidateSingleField(patientData.dob) ? setDobErrorText('DOB cannot be blank') : setDobErrorText('');

        setaddressError(ValidateSingleField(patientData.address));
        ValidateSingleField(patientData.address) ? setaddressErrorText('Address cannot be blank') : setaddressErrorText('');

        setAdharrError(ValidateSingleField(patientData.adharr));
        ValidateSingleField(patientData.adharr) ? setAdharrErrorText('Adharr cannot be blank') : setAdharrErrorText('');

        setBloodGroupError(ValidateSingleField(patientData.bloodGroup));
        ValidateSingleField(patientData.bloodGroup) ? setBloodGroupErrorText('Blood group cannot be blank') : setBloodGroupErrorText('');

        setAgeError(ValidateSingleField(patientData.age));
        ValidateSingleField(patientData.age) ? setAgeErrorText('Age cannot be blank') : setAgeErrorText('');

        setGenderError(ValidateSingleField(patientData.gender));
        ValidateSingleField(patientData.gender) ? setGenderErrorText('Gender cannot be blank') : setGenderErrorText('');

        setpFirstNameError(ValidateSingleField(patientData.pFirstName));
        ValidateSingleField(patientData.pFirstName) ? setpFirstNameErrorText('First Name cannot be blank') : setpFirstNameErrorText('');

        setpLastNameError(ValidateSingleField(patientData.pLastName));
        ValidateSingleField(patientData.pLastName) ? setpLastNameErrorText('Last Name cannot be blank') : setpLastNameErrorText('');

        setpRelationError(ValidateSingleField(patientData.pRelation));
        ValidateSingleField(patientData.pRelation) ? setpRelationErrorText('Relation cannot be blank') : setpRelationErrorText('');

        setpContactError(ValidateSingleField(patientData.pContact));
        ValidateSingleField(patientData.pContact) ? setpContactErrorText('Contact cannot be blank') : setpContactErrorText('');




        if (
            !ValidateSingleField(patientData.firstName) &&
            !ValidateSingleField(patientData.lastName) &&
            !ValidateSingleField(patientData.contact) &&
            !ValidateSingleField(patientData.dob) &&
            !ValidateSingleField(patientData.address) &&
            !ValidateSingleField(patientData.adharr) &&
            !ValidateSingleField(patientData.bloodGroup) &&
            !ValidateSingleField(patientData.age) &&
            !ValidateSingleField(patientData.gender) &&
            !ValidateSingleField(patientData.pFirstName) &&
            !ValidateSingleField(patientData.pLastName) &&
            !ValidateSingleField(patientData.pRelation) &&
            !ValidateSingleField(patientData.pContact) &&


            patientData.dob.length <= 10

        ) {


            return true;


        }
        return false;


    }
    const [loader, setLoader] = useState(true);
    const navigate=useNavigate();
    React.useEffect(() => {
        axiosInstance.get(`/auth/get_details/`).then(res => {
            console.log(res.data);
            let tmp = res.data.details;
            setPatientData({
                firstName: tmp.full_name.split(' ')[0],
                lastName: tmp.full_name.split(' ')[1],
                dob: tmp.dob,
                contact: tmp.phone,
                address: tmp.address,
                adharr: tmp.aadhaar,
                bloodGroup: tmp.blood_group,
                age: tmp.age,
                gender: tmp.gender,
                pFirstName: tmp.emergency_contact_name.split(' ')[0],
                pLastName: tmp.emergency_contact_name.split(' ')[1],
                pRelation: tmp.emergency_contact_relation,
                pContact: tmp.emergency_contact_phone,

            });
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }, []);

    return (
        loader ?
            <div style={{ display: 'flex', height: '100vh', justifyContent: 'center' }} >
                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
            </div>
            :
            <ThemeProvider theme={theme}>
                <div style={{ position: 'relative', zIndex: '1' }}>
                    <Grid container style={{ minHeight: '100vh' }}>

                        <Grid style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'auto', backgroundImage: `url(${pic})` }} item lg={12} md={12} sm={12} xs={12}>
                            <div style={{
                                width: '100%',
                                maxWidth: '45em'
                            }}>


                                <Grid style={{ padding: width > 430 ? '3em 2.3em' : '3em 1em', minHeight: '100vh', color: '#fff' }} container direction='column' >
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center' }}>
                                        <Typography className='text' variant='h4' >Edit Patient Profile</Typography>
                                        <br />

                                    </div>


                                    <br />
                                    <form onSubmit={e => e.preventDefault()}>
                                        <Grid container direction='row' spacing={2}>
                                            <Grid spacing={2} container item lg={12} md={12} sm={12} xs={12} direction='row'>

                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>First name</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='First name' variant='outlined'
                                                        error={firstNameError}
                                                        value={patientData.firstName}
                                                        onChange={(event) => {
                                                            setFirstNameError(false);
                                                            setFirstNameErrorText('');
                                                            setPatientData({ ...patientData, firstName: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{firstNameErrorText}</FormHelperText>
                                                </Grid>
                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Last name</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='Last name' variant='outlined'
                                                        error={lastNameError}
                                                        value={patientData.lastName}
                                                        onChange={(event) => {
                                                            setLastNameError(false);
                                                            setLastNameErrorText('');
                                                            setPatientData({ ...patientData, lastName: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{lastNameErrorText}</FormHelperText>
                                                </Grid>



                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Date of Birth</span>
                                                    <TextField
                                                        className='inputField'
                                                        placeholder='dd/mm/yyyy'
                                                        variant='outlined'
                                                        type='date'
                                                        error={dobError}
                                                        value={patientData.dob}
                                                        onChange={(event) => {

                                                            setDobError(false);
                                                            setDobErrorText('');
                                                            if (event.target.value.length > 10) {
                                                                setDobError(true);
                                                                setDobErrorText('Please enter correct date');
                                                            }
                                                            setPatientData({ ...patientData, dob: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{dobErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Address</span>
                                                    <TextField
                                                        className='inputField'
                                                        placeholder='Flatno:, Landmark, City - Pincode'
                                                        variant='outlined'
                                                        multiline
                                                        maxRows={3}
                                                        error={addressError}
                                                        value={patientData.address}
                                                        onChange={(event) => {
                                                            setaddressError(false);
                                                            setaddressErrorText('');
                                                            setPatientData({ ...patientData, address: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{addressErrorText}</FormHelperText>

                                                </Grid>
                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Contact</span>
                                                    <MuiPhoneNumber
                                                        defaultCountry={'in'}
                                                        className='inputField'
                                                        placeholder='9876543210'
                                                        variant='outlined'
                                                        error={contactError}
                                                        value={patientData.contact}
                                                        onChange={(event) => {
                                                            setContactError(false);
                                                            setContactErrorText('');
                                                            setPatientData({ ...patientData, contact: event })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{contactErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Adharr Number</span>
                                                    <TextField
                                                        className='inputField'
                                                        placeholder='1129NXEP4K'
                                                        variant='outlined'
                                                        type="text"
                                                        error={adharrError}
                                                        value={patientData.adharr}
                                                        onChange={(event) => {
                                                            setAdharrError(false);
                                                            setAdharrErrorText('');
                                                            setPatientData({ ...patientData, adharr: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{adharrErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Blood Group</span>
                                                    <FormControl error={bloodGroupError}>
                                                        <Select
                                                            className='inputField'
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={patientData.bloodGroup}
                                                            onChange={(event) => {
                                                                setBloodGroupError(false);
                                                                setBloodGroupErrorText('');
                                                                setPatientData({ ...patientData, bloodGroup: event.target.value })
                                                            }}
                                                        >

                                                            <MenuItem value={"A+"}>A+</MenuItem>
                                                            <MenuItem value={"A-"}>A-</MenuItem>
                                                            <MenuItem value={"B+"}>B+</MenuItem>
                                                            <MenuItem value={"B-"}>B-</MenuItem>
                                                            <MenuItem value={"AB+"}>AB+</MenuItem>
                                                            <MenuItem value={"AB-"}>AB-</MenuItem>
                                                            <MenuItem value={"O+"}>O+</MenuItem>
                                                            <MenuItem value={"O-"}>O-</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormHelperText style={{ color: 'red' }}>{bloodGroupErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Age</span>
                                                    <TextField
                                                        className='inputField'
                                                        placeholder='34'
                                                        variant='outlined'
                                                        type='text'
                                                        error={ageError}
                                                        value={patientData.age}

                                                        onChange={(event) => {
                                                            setAgeError(false);
                                                            setAgeErrorText('');
                                                            setPatientData({ ...patientData, age: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{ageErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Gender</span>
                                                    <FormControl error={genderError}>
                                                        <Select
                                                            className='inputField'
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={patientData.gender}
                                                            onChange={(event) => {
                                                                setGenderError(false);
                                                                setGenderErrorText('');
                                                                setPatientData({ ...patientData, gender: event.target.value })
                                                            }}
                                                        >

                                                            <MenuItem value={"Male"}>Male</MenuItem>
                                                            <MenuItem value={"Female"}>Female</MenuItem>
                                                            <MenuItem value={"Other"}>Other</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormHelperText style={{ color: 'red' }}>{genderErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center' }}>
                                                        <Typography className='text' variant='h4' >Person to contact (Emergency)</Typography>
                                                        <br />

                                                    </div>
                                                </Grid>
                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>First name</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='Last name' variant='outlined'
                                                        error={pFirstNameError}
                                                        value={patientData.pFirstName}
                                                        onChange={(event) => {
                                                            setpFirstNameError(false);
                                                            setpFirstNameErrorText('');
                                                            setPatientData({ ...patientData, pFirstName: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{pFirstNameErrorText}</FormHelperText>
                                                </Grid>
                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Last name</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='Last name' variant='outlined'
                                                        error={pLastNameError}
                                                        value={patientData.pLastName}
                                                        onChange={(event) => {
                                                            setpLastNameError(false);
                                                            setpLastNameErrorText('');
                                                            setPatientData({ ...patientData, pLastName: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{pLastNameErrorText}</FormHelperText>
                                                </Grid>

                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Relation</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='Uncle/Mother' variant='outlined'
                                                        error={pRelationError}
                                                        value={patientData.pRelation}
                                                        onChange={(event) => {
                                                            setpRelationError(false);
                                                            setpRelationErrorText('');
                                                            setPatientData({ ...patientData, pRelation: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{pRelationErrorText}</FormHelperText>
                                                </Grid>
                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Contact</span>
                                                    <MuiPhoneNumber
                                                        defaultCountry={'in'}
                                                        className='inputField'
                                                        placeholder='9876543210'
                                                        variant='outlined'
                                                        error={pContactError}
                                                        value={patientData.pContact}
                                                        onChange={(event) => {
                                                            setpContactError(false);
                                                            setpContactErrorText('');
                                                            setPatientData({ ...patientData, pContact: event })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{pContactErrorText}</FormHelperText>
                                                </Grid>


                                                <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ fontSize: '20px', color: themeMain.palette.primary.main, background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 60px' }} className='demoSubmitButton'
                                                        variant="contained"
                                                        onClick={() => {
                                                            console.log(patientData);
                                                            if (validateData()) {
                                                                console.log("Validate Success");
                                                                console.log(patientData);
                                                                const tmpData = {
                                                                    role: 'Patient',
                                                                    first_name: patientData.firstName,
                                                                    last_name: patientData.lastName,
                                                                    phone: patientData.contact,
                                                                    dob: patientData.dob,
                                                                    address: patientData.address,
                                                                    blood_group: patientData.bloodGroup,
                                                                    bed_type: patientData.bedType,
                                                                    age: patientData.age,
                                                                    aadhaar: patientData.adharr,
                                                                    gender: patientData.gender,
                                                                    emergency_contact_name: patientData.pFirstName + " " + patientData.pLastName,
                                                                    emergency_contact_phone: patientData.pContact,
                                                                    emergency_contact_relation: patientData.pRelation
                                                                }

                                                                setLoader(true);

                                                                axiosInstance.put('/auth/get_details/', tmpData).then((res) => {
                                                                    setLoader(false);
                                                                    navigate('/patient-dashboard');
                                                                }).catch((err) => {

                                                                });



                                                            }



                                                        }
                                                        }
                                                    >Update</Button></div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>

                                </Grid>
                            </div>
                        </Grid>

                    </Grid>
                </div>

            </ThemeProvider>
    );
}

export default PatientRegister;