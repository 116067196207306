import { ThemeProvider } from "@mui/material";
import { Checkbox, TextField, Button, Box, CircularProgress,ListItemText, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Typography, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './style.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import themeMain from '../../../theme';
import { useWindowDimensions } from '../../../utility/dimensions';
import MuiPhoneNumber from 'material-ui-phone-number';
import FormControl from '@mui/material/FormControl';
import { createTheme } from '@material-ui/core/styles';
import pic from '../../../assets/background.jpg';
import axiosInstance from "../../../utility/axios";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        
        
      },
    },
  };


const DoctorRegister = (props) => {

    const { width } = useWindowDimensions();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate=useNavigate();
    //Form data
    const [doctorData, setDoctorData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        dob: '',
        contact: '',
        address: '',
        adharr: '',
        bloodGroup: "",
        age: '',
        gender: '',
        specialization: '',
        available_days: [0,0,0,0,0,0,0]


    })

    const [available_days, setAvailable_days] = useState([]);

    const days = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];

    const theme = createTheme({
        typography: {
            fontFamily: [
                'Open Sans',

            ].join(','),
        },
    });


    //states

    const [firstNameError, setFirstNameError] = React.useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = React.useState("");

    const [lastNameError, setLastNameError] = React.useState(false);
    const [lastNameErrorText, setLastNameErrorText] = React.useState("");

    const [emailError, setemailError] = React.useState(false);
    const [emailErrorText, setemailErrorText] = React.useState("");

    const [addressError, setaddressError] = React.useState(false);
    const [addressErrorText, setaddressErrorText] = React.useState("");

    const [dobError, setDobError] = React.useState(false);
    const [dobErrorText, setDobErrorText] = React.useState("");

    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorText, setPasswordErrorText] = React.useState("");

    const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = React.useState("");

    const [contactError, setContactError] = React.useState(false);
    const [contactErrorText, setContactErrorText] = React.useState("");

    const [adharrError, setAdharrError] = React.useState(false);
    const [adharrErrorText, setAdharrErrorText] = React.useState("");

    const [bloodGroupError, setBloodGroupError] = React.useState(false);
    const [bloodGroupErrorText, setBloodGroupErrorText] = React.useState("");

    const [ageError, setAgeError] = React.useState(false);
    const [ageErrorText, setAgeErrorText] = React.useState("");

    const [genderError, setGenderError] = React.useState(false);
    const [genderErrorText, setGenderErrorText] = React.useState("");

    const [specializationError, setSpecializationError] = React.useState(false);
    const [specializationErrorText, setSpecializationErrorText] = React.useState("");

    const [availableDaysError, setAvailableDaysError] = React.useState(false);
    const [availableDaysErrorText, setAvailableDaysErrorText] = React.useState("");

    //password visibility
    const [values1, setValues1] = React.useState({
        password: '',
        showPassword: false,
    });
    const [values2, setValues2] = React.useState({
        password: '',
        showPassword: false,
    });

    //Handle Password
    const handleChange1 = (prop) => (event) => {
        setPasswordError(false);
        setPasswordErrorText('');
        setValues1({ ...values1, [prop]: event.target.value });
        setDoctorData({ ...doctorData, [prop]: event.target.value });
    };

    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    };

    const handleChange2 = (prop) => (event) => {
        setConfirmPasswordError(false);
        setConfirmPasswordErrorText('');
        setValues2({ ...values2, [prop]: event.target.value });
    };
    const handleClickShowPassword2 = () => {
        setValues2({ ...values2, showPassword: !values2.showPassword });
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const ValidateDaysField = (data) => {
       var count= 0;
       data.map((value)=>{
           if(value==1){
               count++;
           }
       })

       if(count == 0){
           return true;
       }
       return false;

    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleDays = () =>{
        
            var tmp=[];
            tmp.push(available_days.includes("Sunday")?1:0);
            tmp.push(available_days.includes("Monday")?1:0);
            tmp.push(available_days.includes("Tuesday")?1:0);
            tmp.push(available_days.includes("Wednesday")?1:0);
            tmp.push(available_days.includes("Thursday")?1:0);
            tmp.push(available_days.includes("Friday")?1:0);
            tmp.push(available_days.includes("Saturday")?1:0);
            
            setDoctorData({ ...doctorData, available_days:tmp })
            console.log(tmp);
       
    }

    const validateData = () => {
        setFirstNameError(ValidateSingleField(doctorData.firstName));
        ValidateSingleField(doctorData.firstName) ? setFirstNameErrorText('First Name cannot be blank') : setFirstNameErrorText('');


        setLastNameError(ValidateSingleField(doctorData.lastName));
        ValidateSingleField(doctorData.lastName) ? setLastNameErrorText('Last Name cannot be blank') : setLastNameErrorText('');


        setemailError(ValidateSingleField(doctorData.email));
        ValidateSingleField(doctorData.email) ? setemailErrorText('Email cannot be blank') : setemailErrorText('');
        if (doctorData.email) {
            setemailError(!validateEmail(doctorData.email));
            setemailErrorText(!validateEmail(doctorData.email) ? 'Please enter correct email' : '');
        }

        setContactError(ValidateSingleField(doctorData.contact));
        ValidateSingleField(doctorData.contact) ? setContactErrorText('Contact cannot be blank') : setContactErrorText('');

        setPasswordError(ValidateSingleField(values1.password));
        ValidateSingleField(values1.password) ? setPasswordErrorText('Password cannot be blank') : setPasswordErrorText('');

        setConfirmPasswordError(ValidateSingleField(values2.password));
        ValidateSingleField(values2.password) ? setConfirmPasswordErrorText('Confirm Password cannot be blank') : setConfirmPasswordErrorText('');

        if (values1.password !== values2.password) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorText('Password doesn\'t match');
        }

        if (doctorData.dob.length > 10) {
            setDobError(true);
            setDobErrorText('Please enter correct date');
        }

        setDobError(ValidateSingleField(doctorData.dob));
        ValidateSingleField(doctorData.dob) ? setDobErrorText('DOB cannot be blank') : setDobErrorText('');

        setaddressError(ValidateSingleField(doctorData.address));
        ValidateSingleField(doctorData.address) ? setaddressErrorText('Address cannot be blank') : setaddressErrorText('');

        setAdharrError(ValidateSingleField(doctorData.adharr));
        ValidateSingleField(doctorData.adharr) ? setAdharrErrorText('Adharr cannot be blank') : setAdharrErrorText('');

        setBloodGroupError(ValidateSingleField(doctorData.bloodGroup));
        ValidateSingleField(doctorData.bloodGroup) ? setBloodGroupErrorText('Blood group cannot be blank') : setBloodGroupErrorText('');

        setAgeError(ValidateSingleField(doctorData.age));
        ValidateSingleField(doctorData.age) ? setAgeErrorText('Age cannot be blank') : setAgeErrorText('');

        setGenderError(ValidateSingleField(doctorData.gender));
        ValidateSingleField(doctorData.gender) ? setGenderErrorText('Gender cannot be blank') : setGenderErrorText('');

        setSpecializationError(ValidateSingleField(doctorData.specialization));
        ValidateSingleField(doctorData.specialization) ? setSpecializationErrorText('Specialization cannot be blank') : setSpecializationErrorText('');

        setAvailableDaysError(ValidateDaysField(doctorData.available_days));
        ValidateDaysField(doctorData.available_days) ? setAvailableDaysErrorText('Available Days cannot be blank') : setAvailableDaysErrorText('');


        if ((values1.password == values2.password) &&
            validateEmail(doctorData.email) &&
            !ValidateSingleField(doctorData.firstName) &&
            !ValidateSingleField(doctorData.lastName) &&
            !ValidateSingleField(doctorData.email) &&
            !ValidateSingleField(values1.password) &&
            !ValidateSingleField(values2.password) &&
            !ValidateSingleField(doctorData.contact) &&
            !ValidateSingleField(doctorData.dob) &&
            !ValidateSingleField(doctorData.address) &&
            !ValidateSingleField(doctorData.adharr) &&
            !ValidateSingleField(doctorData.bloodGroup) &&
            !ValidateSingleField(doctorData.age) &&
            !ValidateSingleField(doctorData.gender) &&
            !ValidateSingleField(doctorData.specialization) &&
            !ValidateDaysField(doctorData.available_days) &&

            doctorData.dob.length <= 10

        ) {


            return true;


        }
        return false;


    }



    return (

        <ThemeProvider theme={theme}>
            <div style={{ position: 'relative', zIndex: '1' }}>
                <Grid container style={{ minHeight: '100vh' }}>

                    <Grid style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'auto', backgroundImage: `url(${pic})` }} item lg={12} md={12} sm={12} xs={12}>
                        <div style={{
                            width: '100%',
                            maxWidth: '45em'
                        }}>
                            <Grid style={{ padding: width > 430 ? '3em 2.3em' : '3em 1em', minHeight: '100vh', color: '#fff' }} container direction='column' >
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center' }}>
                                    <Typography className='text' variant='h4' >Doctor Registration</Typography>
                                    <br />

                                </div>


                                <br />
                                <form onSubmit={e => e.preventDefault()}>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid spacing={2} container item lg={12} md={12} sm={12} xs={12} direction='row'>

                                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                <span className='label'>First name</span>
                                                <TextField className='inputField' type="text"
                                                    placeholder='First name' variant='outlined'
                                                    error={firstNameError}
                                                    value={doctorData.firstName}
                                                    onChange={(event) => {
                                                        setFirstNameError(false);
                                                        setFirstNameErrorText('');
                                                        setDoctorData({ ...doctorData, firstName: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{firstNameErrorText}</FormHelperText>
                                            </Grid>
                                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                <span className='label'>Last name</span>
                                                <TextField className='inputField' type="text"
                                                    placeholder='Last name' variant='outlined'
                                                    error={lastNameError}
                                                    value={doctorData.lastName}
                                                    onChange={(event) => {
                                                        setLastNameError(false);
                                                        setLastNameErrorText('');
                                                        setDoctorData({ ...doctorData, lastName: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{lastNameErrorText}</FormHelperText>
                                            </Grid>



                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Email address</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='name@example.com'
                                                    variant='outlined'
                                                    type='email'

                                                    error={emailError}
                                                    value={doctorData.email}

                                                    onChange={(event) => {
                                                        setemailError(false);
                                                        setemailErrorText('');
                                                        setDoctorData({ ...doctorData, email: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{emailErrorText}</FormHelperText>

                                            </Grid>
                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Password</span>
                                                <OutlinedInput
                                                    className='inputField'
                                                    placeholder='6 characters, 1 capital letter'
                                                    variant='outlined'
                                                    error={passwordError}
                                                    type={values1.showPassword ? 'text' : 'password'}
                                                    value={values1.password}
                                                    onChange={handleChange1('password')}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton tabIndex='-1'
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword1}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {values1.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText style={{ color: 'red' }}>{passwordErrorText}</FormHelperText>
                                            </Grid>
                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Confirm Password</span>
                                                <OutlinedInput
                                                    className='inputField'
                                                    placeholder='6 characters, 1 capital letter'
                                                    variant='outlined'
                                                    error={confirmPasswordError}
                                                    type={values2.showPassword ? 'text' : 'password'}
                                                    value={values2.password}
                                                    onChange={handleChange2('password')}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton tabIndex='-1'
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword2}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {values2.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText style={{ color: 'red' }}>{confirmPasswordErrorText}</FormHelperText>
                                            </Grid>
                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Date of Birth</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='dd/mm/yyyy'
                                                    variant='outlined'
                                                    type='date'
                                                    error={dobError}
                                                    value={doctorData.dob}
                                                    onChange={(event) => {

                                                        setDobError(false);
                                                        setDobErrorText('');
                                                        if (event.target.value.length > 10) {
                                                            setDobError(true);
                                                            setDobErrorText('Please enter correct date');
                                                        }
                                                        setDoctorData({ ...doctorData, dob: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{dobErrorText}</FormHelperText>

                                            </Grid>
                                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                <span className='label'>Specialization</span>
                                                <TextField className='inputField' type="text"
                                                    placeholder='MBBS' variant='outlined'
                                                    error={specializationError}
                                                    value={doctorData.specialization}
                                                    onChange={(event) => {
                                                        setSpecializationError(false);
                                                        setSpecializationErrorText('');
                                                        setDoctorData({ ...doctorData, specialization: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{specializationErrorText}</FormHelperText>
                                            </Grid>


                                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                <span className='label'>Available Days</span>
                                                <FormControl error={availableDaysError}>
                                                <Select
                                                    className='inputField'
                                                    required
                                                    style={{ width: '100%' }}
                                                    labelId="demo-mutiple-chip-label"
                                                    id="demo-mutiple-chip"
                                                    multiple
                                                    value={available_days}
                                                    onChange={(event) => {
                                                        setAvailableDaysError(false);
                                                        setAvailableDaysErrorText('');
                                                        setAvailable_days(event.target.value);
                                                        handleDays();
                                                    }}
                                                    input={<OutlinedInput/>}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                    >
                                                    {days.map((day) => (
                                                        <MenuItem key={day} value={day}>
                                                        <Checkbox checked={available_days.indexOf(day) > -1} />
                                                        <ListItemText primary={day} />
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                                </FormControl>
                                                <FormHelperText style={{ color: 'red' }}>{availableDaysErrorText}</FormHelperText>
                                            </Grid>


                                            <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Address</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='Flatno:, Landmark, City - Pincode'
                                                    variant='outlined'
                                                    multiline
                                                    maxRows={3}
                                                    error={addressError}
                                                    value={doctorData.address}
                                                    onChange={(event) => {
                                                        setaddressError(false);
                                                        setaddressErrorText('');
                                                        setDoctorData({ ...doctorData, address: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{addressErrorText}</FormHelperText>

                                            </Grid>
                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Contact</span>
                                                <MuiPhoneNumber
                                                    defaultCountry={'in'}
                                                    className='inputField'
                                                    placeholder='9876543210'
                                                    variant='outlined'
                                                    error={contactError}
                                                    value={doctorData.contact}
                                                    onChange={(event) => {
                                                        setContactError(false);
                                                        setContactErrorText('');
                                                        setDoctorData({ ...doctorData, contact: event })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{contactErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Adharr Number</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='1129NXEP4K'
                                                    variant='outlined'
                                                    type="text"
                                                    error={adharrError}
                                                    value={doctorData.adharr}
                                                    onChange={(event) => {
                                                        setAdharrError(false);
                                                        setAdharrErrorText('');
                                                        setDoctorData({ ...doctorData, adharr: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{adharrErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Blood Group</span>
                                                <FormControl error={bloodGroupError}>
                                                    <Select
                                                        className='inputField'
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={doctorData.bloodGroup}
                                                        onChange={(event) => {
                                                            setBloodGroupError(false);
                                                            setBloodGroupErrorText('');
                                                            setDoctorData({ ...doctorData, bloodGroup: event.target.value })
                                                        }}
                                                    >

                                                        <MenuItem value={"A+"}>A+</MenuItem>
                                                        <MenuItem value={"A-"}>A-</MenuItem>
                                                        <MenuItem value={"B+"}>B+</MenuItem>
                                                        <MenuItem value={"B-"}>B-</MenuItem>
                                                        <MenuItem value={"AB+"}>AB+</MenuItem>
                                                        <MenuItem value={"AB-"}>AB-</MenuItem>
                                                        <MenuItem value={"O+"}>O+</MenuItem>
                                                        <MenuItem value={"O-"}>O-</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormHelperText style={{ color: 'red' }}>{bloodGroupErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Age</span>
                                                <TextField
                                                    className='inputField'
                                                    placeholder='34'
                                                    variant='outlined'
                                                    type='text'
                                                    error={ageError}
                                                    value={doctorData.age}

                                                    onChange={(event) => {
                                                        setAgeError(false);
                                                        setAgeErrorText('');
                                                        setDoctorData({ ...doctorData, age: event.target.value })
                                                    }}

                                                />
                                                <FormHelperText style={{ color: 'red' }}>{ageErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                <span className='label'>Gender</span>
                                                <FormControl error={genderError}>
                                                    <Select
                                                        className='inputField'
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={doctorData.gender}
                                                        onChange={(event) => {
                                                            setGenderError(false);
                                                            setGenderErrorText('');
                                                            setDoctorData({ ...doctorData, gender: event.target.value })
                                                        }}
                                                    >

                                                        <MenuItem value={"Male"}>Male</MenuItem>
                                                        <MenuItem value={"Female"}>Female</MenuItem>
                                                        <MenuItem value={"Other"}>Other</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormHelperText style={{ color: 'red' }}>{genderErrorText}</FormHelperText>

                                            </Grid>

                                            <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                {loader ? <div style={{ display: 'flex', justifyContent: 'center' }} >
                                                    <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                                </div> : <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ fontSize: '20px', color: themeMain.palette.primary.main, background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 60px' }} className='demoSubmitButton'
                                                    variant="contained"
                                                    onClick={() => {
                                                        console.log(doctorData);
                                                        if (validateData()) {
                                                            console.log("Validate Success");
                                                            console.log(doctorData);
                                                            const tmpData = {
                                                                username: doctorData.email,
                                                                email: doctorData.email,
                                                                first_name: doctorData.firstName,
                                                                last_name: doctorData.lastName,
                                                                password: doctorData.password,
                                                                password2: doctorData.password,
                                                                phone: doctorData.contact,
                                                                dob: doctorData.dob,
                                                                aadhaar: doctorData.adharr,
                                                                address: doctorData.address,
                                                                blood_group: doctorData.bloodGroup,
                                                                age: doctorData.age,
                                                                gender: doctorData.gender,
                                                                specialization: doctorData.specialization
                                                            }


                                                            setLoader(true);

                                                            axiosInstance.post('/doctor/register/', tmpData).then((res) => {
                                                                setLoader(false);
                                                                setIsError(false);
                                                                setSnackbarMessage("Registration Successful");
                                                                setOpen(true);
                                                                navigate('/login');
                                                                // console.log(res.data);

                                                            }).catch((err) => {
                                                                console.log(err.response.data.error);
                                                                setLoader(false);

                                                                setIsError(true);
                                                                let tmp = '';
                                                                if (err.response.data.error.password) {
                                                                    err.response.data.error.password.map(e => {
                                                                        tmp += e.toString() + '\n';
                                                                    })
                                                                }
                                                                if (err.response.data.error.username || err.response.data.error.email) {
                                                                    tmp += err.response.data.error.username[0];
                                                                }

                                                                setSnackbarMessage(tmp);
                                                                setOpen(true);


                                                            });


                                                        }



                                                    }
                                                    }
                                                >Submit</Button></div>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>

                            </Grid>
                        </div>
                    </Grid>
                    <Snackbar open={open} autoHideDuration={2000} onClose={() => {
                        setOpen(false);
                    }}>
                        <Alert onClose={() => {
                            setOpen(false);
                        }} severity={isError ? "error" : "success"} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Grid>
            </div>

        </ThemeProvider>
    );
}


export default DoctorRegister;