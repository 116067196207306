import { Avatar, Grid, Typography } from "@mui/material";
import React from "react";
const DocInfoCard = (props) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let Available = [0, 0, 0, 0, 0, 0, 0];
    if (props.doctor.working_days != undefined && props.doctor.working_days != '' && props.doctor.working_days) {
        console.log(props.doctor.working_days);
        Available = props.doctor.working_days;
    }
    return (
        <Grid container style={{
            backgroundColor: '#f0f6ff',
            borderRadius: '5px',
            padding: '20px'
        }} >

            {props.show == undefined ? <span style={{ fontWeight: '500', fontSize: '25px' }}>Doctor Operating</span> : <></>}

            <Grid style={{ border: '3px solid rgb(0,46,108)', gap: 5, height: '15em', flexWrap: 'nowrap' }} className='commonCard' direction={'column'} container alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Grid item lg={12} style={{}}>
                    <Avatar
                        alt="Remy Sharp"
                        src= {props.img!=undefined ? props.img : "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"} 
                        sx={{ width: 56, height: 56 }}
                    />
                    <Typography style={{ fontSize: '18px', fontWeight: '600' }}>Dr. {props.doctor.full_name}</Typography>
                    <Typography style={{ fontSize: '16px', fontWeight: '500' }}>Specializaton: {props.doctor.specialization}</Typography>
                    <Typography style={{ fontSize: '16px', fontWeight: '500' }}>Contact: {props.doctor.phone}</Typography>
                    {/* <Typography style={{ fontSize: '16px', fontWeight: '500' }}>Cabin: {props.doctor.location}</Typography> */}
                    <Typography style={{ fontSize: '16px', fontWeight: '600' }}>Available Days &nbsp; :-
                        <br />

                        {Available.map((item, index) => {
                            if (item)
                                return <mark key={index} className="green">{days[index]} &nbsp;</mark>;
                            else
                                return <mark key={index} style={{ color: 'red', background: '#f0f6ff' }}>{days[index]}&nbsp;</mark>;
                        })}
                    </Typography>




                </Grid>
            </Grid>

        </Grid>
    )
}
export default DocInfoCard;