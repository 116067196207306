import React, { Suspense, lazy, useState } from 'react';
import "./style.css";
import NavBar from '../../components/Navbar';
import { CircularProgress, Grid, TextField } from '@mui/material';
import DocInfoCard from '../../components/DocInfoCard';
import axiosInstance from '../../utility/axios';
import Doctor1 from './Doctor1.png';
import Doctor2 from './Doctor2.png';
import Doctor3 from './Doctor3.png';
import Doctor4 from './Doctor4.png';
import Doctor5 from './Doctor5.png';
import Doctor6 from './Doctor6.png';
import Doctor7 from './Doctor7.png';
import Doctor8 from './Doctor8.png';
import Doctor9 from './Doctor9.png';
import Doctor10 from './Doctor10.png';

const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase()
    return array.filter(value => {
        return value.full_name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.specialization.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.phone.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
            value.blood_group.toLowerCase().match(new RegExp(searchTerm, 'g'))

    })
};

const DocAvtar = [ Doctor2, Doctor3, Doctor4, Doctor5, Doctor6, Doctor7, Doctor8, Doctor9, Doctor10, Doctor1];

const DoctorList = () => {

    const [loader, setLoader] = useState(true);
    const [doctors, setDoctors] = useState([]);
    const [stockData1, setStockData1] = useState([]);
    const [count, setCount] = useState(0);

    const handleChange = async (e) => {
        let value = e.target.value;
        console.log(e.target.value);
        if (doctors) {
            if (value.length >= 2) {
                let search = await arraySearch(doctors, value);
                setDoctors(search)
                console.log(search);
                setCount(search.length)

            } else {
                setDoctors(stockData1)
                setCount(stockData1.length)
            }
        }
    }
    React.useEffect(() => {
        axiosInstance.get(`/doctor/doctors_list/`).then(res => {
            setLoader(false);
            console.log(res.data);
            setStockData1(res.data);
            setDoctors(res.data);
        }).catch(err => {
            setLoader(false);
            console.log(err.response)
        });

    }, []);

    return (

        <div className="landingPageContainer"
            style={{
                display: 'flex',
                color: '#000',
                height: '100vh',
                background: '#F0F6FF',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: '#FFFF',
                padding: '0',

            }}
        >

            <NavBar />
            {loader ?
                <div style={{ display: 'flex', height: '100vh', justifyContent: 'center' }} >
                    <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                </div> :
                <Grid container>
                    <Grid style={{
                        paddingTop: '4.5em', paddingRight: '3em',paddingLeft:'3em',alignItems:'center', background: '#F0F6FF',
                    }} container justifyContent={'space-between'}>
                        <h2 style={{fontWeight:'500',margin:'0'}}>Check Doctors availabilty</h2>
                        <TextField
                            className='inputField'
                            placeholder='Search'
                            variant='outlined'
                            type='text'


                            onChange={handleChange}

                        />

                    </Grid>
                    <Grid style={{ background: '#F0F6FF', paddingTop: '1em' }} container>
                        {doctors.map((doctor, index) => {
                            return (
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <DocInfoCard img={DocAvtar[index]} show={true} doctor={doctor} />
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>
            }

        </div>

    );
};

export default DoctorList;

