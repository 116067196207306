import { ThemeProvider } from "@mui/material";
import { Checkbox, TextField, Button, CircularProgress, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './style.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MuiPhoneNumber from 'material-ui-phone-number';
import FormControl from '@mui/material/FormControl';
import themeMain from '../../../theme';
import { createTheme } from '@material-ui/core/styles';
import { useWindowDimensions } from '../../../utility/dimensions';
import pic from '../../../assets/background.jpg'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../utility/axios";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PatientRegister = (props) => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);

    //Form data
    const [patientData, setPatientData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        dob: '',
        contact: '',
        address: '',
        adharr: '',
        bloodGroup: "",
        age: '',
        gender: '',
        pFirstName: '',
        pLastName: '',
        pRelation: '',
        pContact: '',
        doctor: '',
        bedType: ''


    })
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Open Sans',

            ].join(','),
        },
    });

    //states

    const [firstNameError, setFirstNameError] = React.useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = React.useState("");

    const [lastNameError, setLastNameError] = React.useState(false);
    const [lastNameErrorText, setLastNameErrorText] = React.useState("");

    const [emailError, setemailError] = React.useState(false);
    const [emailErrorText, setemailErrorText] = React.useState("");

    const [addressError, setaddressError] = React.useState(false);
    const [addressErrorText, setaddressErrorText] = React.useState("");

    const [dobError, setDobError] = React.useState(false);
    const [dobErrorText, setDobErrorText] = React.useState("");

    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorText, setPasswordErrorText] = React.useState("");

    const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = React.useState("");

    const [contactError, setContactError] = React.useState(false);
    const [contactErrorText, setContactErrorText] = React.useState("");

    const [adharrError, setAdharrError] = React.useState(false);
    const [adharrErrorText, setAdharrErrorText] = React.useState("");

    const [bloodGroupError, setBloodGroupError] = React.useState(false);
    const [bloodGroupErrorText, setBloodGroupErrorText] = React.useState("");

    const [ageError, setAgeError] = React.useState(false);
    const [ageErrorText, setAgeErrorText] = React.useState("");

    const [genderError, setGenderError] = React.useState(false);
    const [genderErrorText, setGenderErrorText] = React.useState("");

    const [pFirstNameError, setpFirstNameError] = React.useState(false);
    const [pFirstNameErrorText, setpFirstNameErrorText] = React.useState("");

    const [pLastNameError, setpLastNameError] = React.useState(false);
    const [pLastNameErrorText, setpLastNameErrorText] = React.useState("");

    const [pRelationError, setpRelationError] = React.useState(false);
    const [pRelationErrorText, setpRelationErrorText] = React.useState("");

    const [pContactError, setpContactError] = React.useState(false);
    const [pContactErrorText, setpContactErrorText] = React.useState("");

    const [doctorError, setDoctorError] = React.useState(false);
    const [doctorErrorText, setDoctorErrorText] = React.useState("");

    const [bedTypeError, setBedTypeError] = React.useState(false);
    const [bedTypeErrorText, setBedTypeErrorText] = React.useState("");



    //password visibility
    const [values1, setValues1] = React.useState({
        password: '',
        showPassword: false,
    });
    const [values2, setValues2] = React.useState({
        password: '',
        showPassword: false,
    });

    //Handle Password
    const handleChange1 = (prop) => (event) => {
        setPasswordError(false);
        setPasswordErrorText('');
        setValues1({ ...values1, [prop]: event.target.value });
        setPatientData({ ...patientData, [prop]: event.target.value });
    };

    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    };

    const handleChange2 = (prop) => (event) => {
        setConfirmPasswordError(false);
        setConfirmPasswordErrorText('');
        setValues2({ ...values2, [prop]: event.target.value });
    };
    const handleClickShowPassword2 = () => {
        setValues2({ ...values2, showPassword: !values2.showPassword });
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validateData = () => {
        setFirstNameError(ValidateSingleField(patientData.firstName));
        ValidateSingleField(patientData.firstName) ? setFirstNameErrorText('First Name cannot be blank') : setFirstNameErrorText('');


        setLastNameError(ValidateSingleField(patientData.lastName));
        ValidateSingleField(patientData.lastName) ? setLastNameErrorText('Last Name cannot be blank') : setLastNameErrorText('');


        setemailError(ValidateSingleField(patientData.email));
        ValidateSingleField(patientData.email) ? setemailErrorText('Email cannot be blank') : setemailErrorText('');
        if (patientData.email) {
            setemailError(!validateEmail(patientData.email));
            setemailErrorText(!validateEmail(patientData.email) ? 'Please enter correct email' : '');
        }

        setContactError(ValidateSingleField(patientData.contact));
        ValidateSingleField(patientData.contact) ? setContactErrorText('Contact cannot be blank') : setContactErrorText('');

        setPasswordError(ValidateSingleField(values1.password));
        ValidateSingleField(values1.password) ? setPasswordErrorText('Password cannot be blank') : setPasswordErrorText('');

        setConfirmPasswordError(ValidateSingleField(values2.password));
        ValidateSingleField(values2.password) ? setConfirmPasswordErrorText('Confirm Password cannot be blank') : setConfirmPasswordErrorText('');

        if (values1.password !== values2.password) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorText('Password doesn\'t match');
        }

        if (patientData.dob.length > 10) {
            setDobError(true);
            setDobErrorText('Please enter correct date');
        }

        setDobError(ValidateSingleField(patientData.dob));
        ValidateSingleField(patientData.dob) ? setDobErrorText('DOB cannot be blank') : setDobErrorText('');

        setaddressError(ValidateSingleField(patientData.address));
        ValidateSingleField(patientData.address) ? setaddressErrorText('Address cannot be blank') : setaddressErrorText('');

        setAdharrError(ValidateSingleField(patientData.adharr));
        ValidateSingleField(patientData.adharr) ? setAdharrErrorText('Adharr cannot be blank') : setAdharrErrorText('');

        setBloodGroupError(ValidateSingleField(patientData.bloodGroup));
        ValidateSingleField(patientData.bloodGroup) ? setBloodGroupErrorText('Blood group cannot be blank') : setBloodGroupErrorText('');

        setAgeError(ValidateSingleField(patientData.age));
        ValidateSingleField(patientData.age) ? setAgeErrorText('Age cannot be blank') : setAgeErrorText('');

        setGenderError(ValidateSingleField(patientData.gender));
        ValidateSingleField(patientData.gender) ? setGenderErrorText('Gender cannot be blank') : setGenderErrorText('');

        setpFirstNameError(ValidateSingleField(patientData.pFirstName));
        ValidateSingleField(patientData.pFirstName) ? setpFirstNameErrorText('First Name cannot be blank') : setpFirstNameErrorText('');

        setpLastNameError(ValidateSingleField(patientData.pLastName));
        ValidateSingleField(patientData.pLastName) ? setpLastNameErrorText('Last Name cannot be blank') : setpLastNameErrorText('');

        setpRelationError(ValidateSingleField(patientData.pRelation));
        ValidateSingleField(patientData.pRelation) ? setpRelationErrorText('Relation cannot be blank') : setpRelationErrorText('');

        setpContactError(ValidateSingleField(patientData.pContact));
        ValidateSingleField(patientData.pContact) ? setpContactErrorText('Contact cannot be blank') : setpContactErrorText('');

        setDoctorError(ValidateSingleField(patientData.doctor));
        ValidateSingleField(patientData.doctor) ? setDoctorErrorText('Doctor Name cannot be blank') : setDoctorErrorText('');

        setBedTypeError(ValidateSingleField(patientData.bedType));
        ValidateSingleField(patientData.bedType) ? setBedTypeErrorText('Bed type cannot be blank') : setBedTypeErrorText('');




        if ((values1.password == values2.password) &&
            validateEmail(patientData.email) &&
            !ValidateSingleField(patientData.firstName) &&
            !ValidateSingleField(patientData.lastName) &&
            !ValidateSingleField(patientData.email) &&
            !ValidateSingleField(values1.password) &&
            !ValidateSingleField(values2.password) &&
            !ValidateSingleField(patientData.contact) &&
            !ValidateSingleField(patientData.dob) &&
            !ValidateSingleField(patientData.address) &&
            !ValidateSingleField(patientData.adharr) &&
            !ValidateSingleField(patientData.bloodGroup) &&
            !ValidateSingleField(patientData.age) &&
            !ValidateSingleField(patientData.gender) &&
            !ValidateSingleField(patientData.pFirstName) &&
            !ValidateSingleField(patientData.pLastName) &&
            !ValidateSingleField(patientData.pRelation) &&
            !ValidateSingleField(patientData.pContact) &&
            !ValidateSingleField(patientData.doctor) &&
            !ValidateSingleField(patientData.bedType) &&

            patientData.dob.length <= 10

        ) {


            return true;


        }
        return false;


    }
    const [doctorList, setDoctorList] = useState();
    React.useEffect(() => {
        axiosInstance.get(`/doctor/doctors_list/`).then(res => {
            setLoader(false);
            console.log(res.data);

            setDoctorList(res.data);
            // setPatientData(res.data);
        }).catch(err => {
            setLoader(false);
            console.log(err.response)
        });

    }, []);

    return (

        loader ?
            <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} >
                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
            </div >
            :
            <ThemeProvider theme={theme}>
                <div style={{ position: 'relative', zIndex: '1' }}>
                    <Grid container style={{ minHeight: '100vh' }}>

                        <Grid style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'auto', backgroundImage: `url(${pic})` }} item lg={12} md={12} sm={12} xs={12}>
                            <div style={{
                                width: '100%',
                                maxWidth: '45em'
                            }}>


                                <Grid style={{ padding: width > 430 ? '3em 2.3em' : '3em 1em', minHeight: '100vh', color: '#fff' }} container direction='column' >
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center' }}>
                                        <Typography className='text' variant='h4' >Patient Registration</Typography>
                                        <br />

                                    </div>


                                    <br />
                                    <form onSubmit={e => e.preventDefault()}>
                                        <Grid container direction='row' spacing={2}>
                                            <Grid spacing={2} container item lg={12} md={12} sm={12} xs={12} direction='row'>

                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>First name</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='First name' variant='outlined'
                                                        error={firstNameError}
                                                        value={patientData.firstName}
                                                        onChange={(event) => {
                                                            setFirstNameError(false);
                                                            setFirstNameErrorText('');
                                                            setPatientData({ ...patientData, firstName: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{firstNameErrorText}</FormHelperText>
                                                </Grid>
                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Last name</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='Last name' variant='outlined'
                                                        error={lastNameError}
                                                        value={patientData.lastName}
                                                        onChange={(event) => {
                                                            setLastNameError(false);
                                                            setLastNameErrorText('');
                                                            setPatientData({ ...patientData, lastName: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{lastNameErrorText}</FormHelperText>
                                                </Grid>



                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Email address</span>
                                                    <TextField
                                                        className='inputField'
                                                        placeholder='name@example.com'
                                                        variant='outlined'
                                                        type='email'

                                                        error={emailError}
                                                        value={patientData.email}

                                                        onChange={(event) => {
                                                            setemailError(false);
                                                            setemailErrorText('');
                                                            setPatientData({ ...patientData, email: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{emailErrorText}</FormHelperText>

                                                </Grid>
                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Password</span>
                                                    <OutlinedInput
                                                        className='inputField'
                                                        placeholder='6 characters, 1 capital letter'
                                                        variant='outlined'
                                                        error={passwordError}
                                                        type={values1.showPassword ? 'text' : 'password'}
                                                        value={values1.password}
                                                        onChange={handleChange1('password')}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton tabIndex='-1'
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword1}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {values1.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{passwordErrorText}</FormHelperText>
                                                </Grid>
                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Confirm Password</span>
                                                    <OutlinedInput
                                                        className='inputField'
                                                        placeholder='6 characters, 1 capital letter'
                                                        variant='outlined'
                                                        error={confirmPasswordError}
                                                        type={values2.showPassword ? 'text' : 'password'}
                                                        value={values2.password}
                                                        onChange={handleChange2('password')}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton tabIndex='-1'
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword2}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {values2.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{confirmPasswordErrorText}</FormHelperText>
                                                </Grid>
                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Date of Birth</span>
                                                    <TextField
                                                        className='inputField'
                                                        placeholder='dd/mm/yyyy'
                                                        variant='outlined'
                                                        type='date'
                                                        error={dobError}
                                                        value={patientData.dob}
                                                        onChange={(event) => {

                                                            setDobError(false);
                                                            setDobErrorText('');
                                                            if (event.target.value.length > 10) {
                                                                setDobError(true);
                                                                setDobErrorText('Please enter correct date');
                                                            }
                                                            setPatientData({ ...patientData, dob: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{dobErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Address</span>
                                                    <TextField
                                                        className='inputField'
                                                        placeholder='Flatno:, Landmark, City - Pincode'
                                                        variant='outlined'
                                                        multiline
                                                        maxRows={3}
                                                        error={addressError}
                                                        value={patientData.address}
                                                        onChange={(event) => {
                                                            setaddressError(false);
                                                            setaddressErrorText('');
                                                            setPatientData({ ...patientData, address: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{addressErrorText}</FormHelperText>

                                                </Grid>
                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Contact</span>
                                                    <MuiPhoneNumber
                                                        defaultCountry={'in'}
                                                        className='inputField'
                                                        placeholder='9876543210'
                                                        variant='outlined'
                                                        error={contactError}
                                                        value={patientData.contact}
                                                        onChange={(event) => {
                                                            setContactError(false);
                                                            setContactErrorText('');
                                                            setPatientData({ ...patientData, contact: event })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{contactErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Adharr Number</span>
                                                    <TextField
                                                        className='inputField'
                                                        placeholder='1129NXEP4K'
                                                        variant='outlined'
                                                        type="text"
                                                        error={adharrError}
                                                        value={patientData.adharr}
                                                        onChange={(event) => {
                                                            setAdharrError(false);
                                                            setAdharrErrorText('');
                                                            setPatientData({ ...patientData, adharr: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{adharrErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Blood Group</span>
                                                    <FormControl error={bloodGroupError}>
                                                        <Select
                                                            className='inputField'
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={patientData.bloodGroup}
                                                            onChange={(event) => {
                                                                setBloodGroupError(false);
                                                                setBloodGroupErrorText('');
                                                                setPatientData({ ...patientData, bloodGroup: event.target.value })
                                                            }}
                                                        >

                                                            <MenuItem value={"A+"}>A+</MenuItem>
                                                            <MenuItem value={"A-"}>A-</MenuItem>
                                                            <MenuItem value={"B+"}>B+</MenuItem>
                                                            <MenuItem value={"B-"}>B-</MenuItem>
                                                            <MenuItem value={"AB+"}>AB+</MenuItem>
                                                            <MenuItem value={"AB-"}>AB-</MenuItem>
                                                            <MenuItem value={"O+"}>O+</MenuItem>
                                                            <MenuItem value={"O-"}>O-</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormHelperText style={{ color: 'red' }}>{bloodGroupErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Age</span>
                                                    <TextField
                                                        className='inputField'
                                                        placeholder='34'
                                                        variant='outlined'
                                                        type='text'
                                                        error={ageError}
                                                        value={patientData.age}

                                                        onChange={(event) => {
                                                            setAgeError(false);
                                                            setAgeErrorText('');
                                                            setPatientData({ ...patientData, age: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{ageErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={6} md={12} s={12} xs={12} direction='column'>
                                                    <span className='label'>Gender</span>
                                                    <FormControl error={genderError}>
                                                        <Select
                                                            className='inputField'
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={patientData.gender}
                                                            onChange={(event) => {
                                                                setGenderError(false);
                                                                setGenderErrorText('');
                                                                setPatientData({ ...patientData, gender: event.target.value })
                                                            }}
                                                        >

                                                            <MenuItem value={"Male"}>Male</MenuItem>
                                                            <MenuItem value={"Female"}>Female</MenuItem>
                                                            <MenuItem value={"Other"}>Other</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormHelperText style={{ color: 'red' }}>{genderErrorText}</FormHelperText>

                                                </Grid>

                                                <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center' }}>
                                                        <Typography className='text' variant='h4' >Person to contact (Emergency)</Typography>
                                                        <br />

                                                    </div>
                                                </Grid>
                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>First name</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='Last name' variant='outlined'
                                                        error={pFirstNameError}
                                                        value={patientData.pFirstName}
                                                        onChange={(event) => {
                                                            setpFirstNameError(false);
                                                            setpFirstNameErrorText('');
                                                            setPatientData({ ...patientData, pFirstName: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{pFirstNameErrorText}</FormHelperText>
                                                </Grid>
                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Last name</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='Last name' variant='outlined'
                                                        error={pLastNameError}
                                                        value={patientData.pLastName}
                                                        onChange={(event) => {
                                                            setpLastNameError(false);
                                                            setpLastNameErrorText('');
                                                            setPatientData({ ...patientData, pLastName: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{pLastNameErrorText}</FormHelperText>
                                                </Grid>

                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Relation</span>
                                                    <TextField className='inputField' type="text"
                                                        placeholder='Uncle/Mother' variant='outlined'
                                                        error={pRelationError}
                                                        value={patientData.pRelation}
                                                        onChange={(event) => {
                                                            setpRelationError(false);
                                                            setpRelationErrorText('');
                                                            setPatientData({ ...patientData, pRelation: event.target.value })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{pRelationErrorText}</FormHelperText>
                                                </Grid>
                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Contact</span>
                                                    <MuiPhoneNumber
                                                        defaultCountry={'in'}
                                                        className='inputField'
                                                        placeholder='9876543210'
                                                        variant='outlined'
                                                        error={pContactError}
                                                        value={patientData.pContact}
                                                        onChange={(event) => {
                                                            setpContactError(false);
                                                            setpContactErrorText('');
                                                            setPatientData({ ...patientData, pContact: event })
                                                        }}

                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>{pContactErrorText}</FormHelperText>
                                                </Grid>

                                                <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center' }}>
                                                        <Typography className='text' variant='h4' >Medical Information</Typography>
                                                        <br />

                                                    </div>
                                                </Grid>

                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Consulting Doctor</span>
                                                    <FormControl error={doctorError}>
                                                        <Select
                                                            className='inputField'
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={patientData.doctor}
                                                            onChange={(event) => {
                                                                setDoctorError(false);
                                                                setDoctorErrorText('');
                                                                setPatientData({ ...patientData, doctor: event.target.value })
                                                            }}
                                                        >

                                                            {/* <MenuItem value={"Doctor 1"}>Doctor 1</MenuItem>
                                                            <MenuItem value={"Doctor 2"}>Doctor 2</MenuItem> */}
                                                            
                                                            {doctorList!=undefined?  doctorList.map((doctor, index) => {
                                                            return <MenuItem value={doctor.id}>{doctor.full_name}</MenuItem>
                                                            }):<></>}
                                                        </Select>
                                                    </FormControl>
                                                    <FormHelperText style={{ color: 'red' }}>{doctorErrorText}</FormHelperText>
                                                </Grid>
                                                <Grid container direction='column' item lg={6} md={12} sm={12} xs={12}>
                                                    <span className='label'>Type of Bed</span>
                                                    <FormControl error={bedTypeError}>
                                                        <Select
                                                            className='inputField'
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={patientData.bedType}
                                                            onChange={(event) => {
                                                                setBedTypeError(false);
                                                                setBedTypeErrorText('');
                                                                setPatientData({ ...patientData, bedType: event.target.value })
                                                            }}
                                                        >

                                                            <MenuItem value={"GeneralWard"}>GeneralWard</MenuItem>
                                                            <MenuItem value={"Delux"}>Delux</MenuItem>
                                                            <MenuItem value={"Private"}>Private</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormHelperText style={{ color: 'red' }}>{bedTypeErrorText}</FormHelperText>
                                                </Grid>


                                                <Grid container item lg={12} md={12} s={12} xs={12} direction='column'>
                                                    {loader ? <div style={{ display: 'flex', justifyContent: 'center' }} >
                                                        <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                                    </div> : <div style={{ display: 'flex', justifyContent: 'center' }} > <Button type='submit' style={{ fontSize: '20px', color: themeMain.palette.primary.main, background: themeMain.palette.primary.light, fontWeight: '600', padding: '8px 60px' }} className='demoSubmitButton'
                                                        variant="contained"
                                                        onClick={() => {
                                                            if (validateData()) {
                                                                // console.log("Validate Success");
                                                                console.log(patientData);
                                                                const tmpData = {
                                                                    username: patientData.email,
                                                                    email: patientData.email,
                                                                    first_name: patientData.firstName,
                                                                    last_name: patientData.lastName,
                                                                    password: patientData.password,
                                                                    password2: patientData.password,
                                                                    phone: patientData.contact,
                                                                    dob: patientData.dob,
                                                                    address: patientData.address,
                                                                    blood_group: patientData.bloodGroup,
                                                                    doctor_id: 1,
                                                                    bed_type: patientData.bedType,
                                                                    age: patientData.age,
                                                                    aadhaar: patientData.adharr,
                                                                    gender: patientData.gender,
                                                                    emergency_contact_name: patientData.pFirstName + " " + patientData.pLastName,
                                                                    emergency_contact_phone: patientData.pContact,
                                                                    emergency_contact_relation: patientData.pRelation
                                                                }

                                                                setLoader(true);

                                                                axiosInstance.post('/patient/register/', tmpData).then((res) => {
                                                                    setLoader(false);
                                                                    setIsError(false);
                                                                    setSnackbarMessage("Registration Successful");
                                                                    setOpen(true);
                                                                    navigate('/login');


                                                                }).catch((err) => {
                                                                    console.log(err.response.data.error);
                                                                    setLoader(false);

                                                                    setIsError(true);
                                                                    let tmp = '';
                                                                    if (err.response.data.error.password) {
                                                                        err.response.data.error.password.map(e => {
                                                                            tmp += e.toString() + '\n';
                                                                        })
                                                                    }
                                                                    if (err.response.data.error.username || err.response.data.error.email) {
                                                                        tmp += err.response.data.error.username[0];
                                                                    }
                                                                    if (tmp == '')
                                                                        tmp += err.response.data.error;
                                                                    setSnackbarMessage(tmp);
                                                                    setOpen(true);

                                                                });


                                                            }



                                                        }
                                                        }
                                                    >Submit</Button></div>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>

                                </Grid>
                                <Snackbar open={open} autoHideDuration={2000} onClose={() => {
                                    setOpen(false);
                                }}>
                                    <Alert onClose={() => {
                                        setOpen(false);
                                    }} severity={isError ? "error" : "success"} sx={{ width: '100%' }}>
                                        {snackbarMessage}
                                    </Alert>
                                </Snackbar>
                            </div>
                        </Grid>

                    </Grid>
                </div>

            </ThemeProvider>
    );
}

export default PatientRegister;