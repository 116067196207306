import QRCode from "qrcode.react";
import React from "react";
const QRGenerator = (props) => {
    return (
       
            <QRCode
                id="qr-gen"
                value={props.title}
                size={290}
                level={"H"}
                includeMargin={true}
            />
    )
}
export default QRGenerator;